html.education {
  font-size: 10px;
}

@media (max-width: 500px) {
  html.education {
    font-size: 9px;
  }
}

.article {
  font-size: 10px;
  max-width: 100%;
}

.article a, .article abbr, .article acronym, .article address, .article applet, .article article, .article aside, .article audio, .article big, .article blockquote, .article body, .article canvas, .article caption, .article cite, .article code, .article dd, .article del, .article details, .article dfn, .article div, .article dl, .article dt, .article em, .article embed, .article fieldset, .article figcaption, .article figure, .article footer, .article form, .article h1, .article h2, .article h3, .article h4, .article h5, .article h6, .article header, .article hgroup, .article html, .article iframe, .article img, .article ins, .article kbd, .article label, .article legend, .article li, .article mark, .article menu, .article nav, .article object, .article ol, .article output, .article p, .article pre, .article q, .article ruby, .article s, .article samp, .article section, .article small, .article span, .article strike, .article strong, .article sub, .article summary, .article sup, .article table, .article tbody, .article td, .article tfoot, .article th, .article thead, .article time, .article tr, .article tt, .article ul, .article var, .article video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.article ol, .article ul {
  list-style: none;
}
.article blockquote, .article q {
  quotes: none;
}
.article blockquote:after, .article blockquote:before, .article q:after, .article q:before {
  content: "";
  content: none;
}
.article img {
  max-width: 100%;
}
.article *, .article :after, .article :before {
  box-sizing: inherit;
}
.article a {
  background-color: transparent;
}
.article a:active, .article a:hover {
  outline: 0;
}
.article b, .article strong {
  font-weight: 700;
}
.article dfn, .article em, .article i {
  font-style: italic;
}
.article h1 {
  margin: 0.67em 0;
  font-size: 2em;
}
.article small {
  font-size: 80%;
}
.article sub, .article sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
.article sup {
  top: -0.5em;
}
.article sub {
  bottom: -0.25em;
}
.article img {
  border: 0;
}
.article svg:not(:root) {
  overflow: hidden;
}
.article mark {
  background-color: #fdffb6;
}
.article code, .article kbd, .article pre, .article samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
.article button, .article input, .article optgroup, .article select, .article textarea {
  margin: 0;
  color: inherit;
  font: inherit;
}
.article button {
  overflow: visible;
  border: none;
}
.article button, .article select {
  text-transform: none;
}
.article button, .article html input[type=button], .article input[type=reset], .article input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}
.article button[disabled], .article html input[disabled] {
  cursor: default;
}
.article button::-moz-focus-inner, .article input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.article input {
  line-height: normal;
}
.article input:focus {
  outline: none;
}
.article input[type=checkbox], .article input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
.article input[type=number]::-webkit-inner-spin-button, .article input[type=number]::-webkit-outer-spin-button {
  height: auto;
}
.article input[type=search] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}
.article input[type=search]::-webkit-search-cancel-button, .article input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
.article legend {
  padding: 0;
  border: 0;
}
.article textarea {
  overflow: auto;
}
.article table {
  border-spacing: 0;
  border-collapse: collapse;
}
.article td, .article th {
  padding: 0;
}
.article html {
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
}
@media (max-width: 500px) {
  .article html {
    font-size: 54.6875%;
  }
}
.article ::-moz-selection {
  text-shadow: none;
  background: #ffd9cc;
}
.article ::selection {
  text-shadow: none;
  background: #ffd9cc;
}
.article hr {
  position: relative;
  display: block;
  width: 100%;
  margin: 2.5em 0 3.5em;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--lightgrey);
}
.article audio, .article canvas, .article iframe, .article img, .article svg, .article video {
  vertical-align: middle;
}
.article fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
.article textarea {
  resize: vertical;
}
.article blockquote, .article dl, .article ol, .article p, .article ul {
  margin: 0 0 1.5em;
}
.article ol, .article ul {
  padding-left: 1.3em;
  padding-right: 1.5em;
}
.article ol ol, .article ol ul, .article ul ol, .article ul ul {
  margin: 0.5em 0 1em;
}
.article ul {
  list-style: disc;
}
.article ol {
  list-style: decimal;
}
.article ol, .article ul {
  max-width: 100%;
}
.article li {
  margin: 0.5em 0;
  padding-left: 0.3em;
  line-height: 1.6em;
}
.article dt {
  float: left;
  margin: 0 20px 0 0;
  width: 120px;
  color: var(--darkgrey);
  font-weight: 500;
  text-align: right;
}
.article dd {
  margin: 0 0 5px;
  text-align: left;
}
.article blockquote {
  margin: 1.5em 0;
  padding: 0 1.6em;
  border-left: 0.5em solid var(--whitegrey);
}
.article blockquote p {
  margin: 0.8em 0;
  font-size: 1.2em;
  font-weight: 300;
}
.article blockquote small {
  display: inline-block;
  margin: 0.8em 0 0.8em 1.5em;
  font-size: 0.9em;
  opacity: 0.8;
}
.article blockquote small:before {
  content: "\2014 \00A0";
}
.article blockquote cite {
  font-weight: 700;
}
.article blockquote cite a {
  font-weight: 400;
}
.article a {
  color: var(--orange);
  text-decoration: none;
}
.article a:hover {
  text-decoration: underline;
}
.article h1, .article h2, .article h3, .article h4, .article h5, .article h6 {
  margin-top: 0;
  line-height: 1.15;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
.article .h1, .article h1 {
  margin: 0 0 0.5em;
  font-size: 5.6rem;
  line-height: 107%;
  font-weight: 700;
  font-family: var(--roobert-medium);
}
@media (max-width: 991px) {
  .article .h1, .article h1 {
    font-size: 4.6rem;
  }
}
@media (max-width: 500px) {
  .article .h1, .article h1 {
    font-size: 32px;
  }
}
.article .h2, .article h2 {
  margin: 1em 0 0.5em;
  font-size: 3rem;
  line-height: 1.27;
  font-weight: 700;
}
@media (max-width: 500px) {
  .article .h2, .article h2 {
    font-size: 22px;
  }
}
.article .h3, .article h3 {
  margin: 1em 0 0.5em;
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 700;
}
@media (max-width: 500px) {
  .article .h3, .article h3 {
    font-size: 17px;
  }
}
.article .h4, .article h4 {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.187;
}
.article .h4, .article .h5, .article h4, .article h5 {
  margin: 1em 0 0.5em;
}
.article .h5, .article h5 {
  font-size: 1.4rem;
  line-height: 1.42;
  font-weight: 500;
}
.article .h6, .article h6 {
  margin: 0.5em 0;
  font-size: 1.2rem;
  font-weight: 600;
}
.article .site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.article .site-main {
  z-index: 100;
  flex-grow: 1;
}
.article .site-main, .article .site-main.outer {
  padding-top: 76px;
}
@media (max-width: 767px) {
  .article .site-main, .article .site-main.outer {
    padding-top: 56px;
  }
}
.article .outer {
  position: relative;
  padding: 0 5vw;
}
.article .inner {
  margin: 0 auto;
  max-width: var(--container-width);
  width: 100%;
}
.article .inner-wide {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1280px) {
  .article .inner-wide {
    margin: 0 auto;
    max-width: var(--container-xl);
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1340px) {
  .article .inner-wide {
    max-width: var(--container-2xl);
  }
}
@media (min-width: 1600px) {
  .article .inner-wide {
    max-width: var(--container-3xl);
  }
}
.article .outer-grey {
  background: var(--whitegrey);
}
.article .outer-grey-half {
  background: linear-gradient(var(--body-bg) 50%, var(--whitegrey) 0);
}
.article .podcast-embed {
  margin: 5.2rem auto 10rem;
  padding: 6.4rem 7rem;
  width: 100%;
  background: #fff;
  border: 3px solid #fff;
  box-shadow: 0 32px 84px rgba(14, 86, 124, 0.1);
  border-radius: 30px;
}
@media (max-width: 1160px) {
  .article .podcast-embed {
    margin: 4.4rem auto 10rem;
    padding: 6.4rem 3.2rem;
  }
}
@media (max-width: 991px) {
  .article .podcast-embed {
    margin: 3.2rem auto 8rem;
    padding: 2rem;
  }
}
.article .button {
  display: inline-block;
  min-width: 160px;
  min-height: 4.9rem;
  padding: 1.6rem 1.5rem;
  border: none;
  border-radius: 10px;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.5em;
  text-align: center;
  color: var(--text-primary);
  background: 0 0;
  outline: none;
  transition: background 0.2s ease, color 0.2s ease, border 0.2s ease, box-shadow 0.2s ease;
  -webkit-font-smoothing: subpixel-antialiased;
}
.article .button, .article .button:hover {
  box-shadow: none;
  text-decoration: none;
}
.article .button:hover {
  background: var(--lightgrey);
}
.article .button-primary {
  color: var(--button-color);
  background: var(--button-bg-primary);
  box-shadow: 0 2px 8px rgba(255, 102, 51, 0.25);
}
.article .button-primary:hover {
  color: #f63;
  background-color: #fff8eb;
}
.article .button-secondary {
  color: var(--button-color);
  background: var(--button-bg-secondary);
  box-shadow: 0 2px 8px rgba(109, 117, 231, 0.25);
}
.article .button-secondary:hover {
  background-color: #f1edfc;
  color: var(--blue);
}
.article .button-primary-lighter {
  color: var(--button-color);
  background: var(--button-bg-primary-lighter);
}
.article .button-primary-lighter:hover {
  background: #fb7b51;
}
.article .button-primary-lighter:active, .article .button-primary-lighter:focus {
  background: #f56f42;
}
.article .button-light {
  color: var(--text-primary);
  background: var(--whitegrey);
}
.article .button-light:hover {
  background: #ededed;
}
.article .button-light:active, .article .button-light:focus {
  background: #e3e3e3;
}
.article .button-subscribe {
  min-width: auto;
  min-height: 4.2rem;
  padding: 1.1rem 1.6rem 1.4rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(255, 102, 51, 0.25);
}
.article .button-full-width {
  width: 100%;
}
.article .button-small {
  padding: 1rem 1.4rem;
  min-height: 3.7rem;
  min-width: 100px;
}
.article .button.disabled, .article .button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
.article .checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 1.8rem;
  line-height: 1.4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.article .checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}
.article .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: var(--lightgrey);
  border-radius: 4px;
}
.article .checkbox-container:hover input ~ .checkmark {
  background-color: var(--orange-light);
}
.article .checkbox-container input:checked ~ .checkmark {
  background-color: var(--orange);
}
.article .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.article .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.article .checkbox-container.confirm-error input ~ .checkmark {
  border: 1px solid var(--red);
}
.article .checkbox-container.confirm-error .error-arrow {
  display: block;
}
.article .checkbox-container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.article .error-arrow {
  display: none;
  background: var(--red);
  height: 3px;
  width: 25px;
  left: -40px;
  top: 13px;
  margin: 0 auto;
  position: absolute;
  cursor: pointer;
}
.article .error-arrow:after, .article .error-arrow:before {
  content: "";
  background: var(--red);
  position: absolute;
  height: 3px;
  width: 10px;
}
.article .error-arrow:before {
  right: -4px;
  bottom: -3px;
  transform: rotate(-45deg);
}
.article .error-arrow:after {
  right: -4px;
  top: -3px;
  transform: rotate(45deg);
}
.article .site-header-background {
  position: relative;
  margin-top: 64px;
  color: #fff;
  background: var(--header-bg) no-repeat 50%;
  background-size: cover;
}
.article .site-header-background:before {
  bottom: 0;
  background: rgba(0, 0, 0, 0.18);
}
.article .site-header-background:after, .article .site-header-background:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: block;
}
.article .site-header-background:after {
  bottom: auto;
  height: 140px;
  background: linear-gradient(rgba(0, 0, 0, 0.15), transparent);
}
.article .site-header-background.no-image:after, .article .site-header-background.no-image:before {
  display: none;
}
.article .site-header-content {
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6vw 3vw;
  min-height: 200px;
  max-height: 340px;
}
.article .site-title {
  z-index: 10;
  margin: 0 0 0 -2px;
  padding: 0;
  font-size: 5rem;
  line-height: 1em;
  font-weight: 600;
}
.article .site-logo {
  max-height: 55px;
}
.article .site-description {
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.1rem;
  line-height: 1.4em;
  font-weight: 400;
  opacity: 0.8;
}
.article .site-home-header {
  z-index: 1000;
}
.article .site-home-header .site-header-background {
  margin-top: 0;
  box-shadow: 0 3px 6px rgba(186, 186, 186, 0.35);
}
.article .site-home-header .site-header-content {
  padding: 5vw 3vw 6vw;
}
.article .site-home-header .site-title {
  font-size: 5.5rem;
  text-align: center;
}
.article .site-home-header .site-description {
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
}
.article .site-archive-header .site-header-content {
  position: relative;
  align-items: stretch;
  padding: 12vw 0 20px;
  min-height: 200px;
  max-height: 600px;
}
.article .site-archive-header .no-image {
  padding-top: 0;
  padding-bottom: 0;
  color: var(--darkgrey);
  background: #fff;
  opacity: 1;
}
.article .site-archive-header .no-image .site-description {
  color: var(--midgrey);
  opacity: 1;
}
.article .site-archive-header .no-image .site-header-content {
  padding: 5vw 0 10px;
  border-bottom: 1px solid #fff;
}
@media (max-width: 900px) {
  .article .site-header-content {
    padding-bottom: 9vw;
  }
}
@media (max-width: 500px) {
  .article .site-home-header .site-title {
    font-size: 4.2rem;
  }
  .article .site-home-header .site-description {
    font-size: 1.8rem;
  }
  .article .site-archive-header .site-header-content {
    flex-direction: column;
    align-items: center;
    min-height: unset;
  }
  .article .site-archive-header .site-title {
    font-size: 4.2rem;
    text-align: center;
  }
  .article .site-archive-header .no-image .site-header-content {
    padding: 12vw 0 20px;
  }
}
.article .site-nav-main {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: linear-gradient(90deg, #151517, #484e53 50%, #151517);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  padding-top: 16px;
  padding-bottom: 16px;
}
.article .site-nav-main .inner {
  position: relative;
}
.article .site-nav {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;
}
.article .site-nav-left-wrapper {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  margin-right: 30px;
}
.article .site-nav-right-wrapper {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.article .site-nav-left, .article .site-nav-right {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding: 10px 0 80px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: nowrap;
  -ms-overflow-scrolling: touch;
}
.article .site-nav-left .nav li:last-of-type {
  padding-right: 20px;
}
.article .site-nav-logo {
  position: relative;
  z-index: 100;
  flex-shrink: 0;
  display: inline-block;
  margin-right: 12px;
  padding: 0;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-transform: none;
}
.article .site-nav-logo:hover {
  text-decoration: none;
}
.article .site-nav-logo-image {
  display: none;
  width: 100%;
  height: auto;
  max-width: 290px;
}
.article .site-nav-logo-image-small {
  display: block;
  width: 100%;
  height: auto;
  max-width: 50px;
}
@media (min-width: 1280px) {
  .article .site-nav-logo-image {
    display: block;
  }
  .article .site-nav-logo-image-small {
    display: none;
  }
}
.article .site-nav-content {
  position: relative;
  align-self: flex-start;
  width: 100%;
  height: 100%;
}
.article .nav-item-icon {
  display: flex;
  justify-content: center;
  margin-left: 3.6rem;
  margin-right: 12px;
  margin-top: 3px;
}
.article .nav-item-icon, .article .nav-item-icon-mobile {
  color: var(--text-primary);
  transition: color 0.3s ease;
}
.article .nav-item-icon-mobile:hover, .article .nav-item-icon:hover {
  color: var(--orange);
}
.article .nav-item-icon-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 60px;
  padding: 17px 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1002;
}
.article .site-nav-menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
}
.article .nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}
.article .nav li {
  display: block;
  padding: 0;
  margin: 0 40px 0 0;
}
.article .nav-dropdown-button, .article .nav-item, .article .nav li a {
  position: relative;
  display: block;
  color: var(--header-bg);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  transition: opacity 0.2s ease-in-out;
}
.article .nav-dropdown-button:hover, .article .nav-item:hover, .article .nav li a:hover {
  text-decoration: none;
  color: var(--orange);
}
.article .nav-dropdown {
  position: relative;
  z-index: 10;
}
.article .nav-dropdown-button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.article .nav-dropdown-button-icon {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  width: 8px;
  height: auto;
  transform: rotate(-90deg);
  margin-left: 8px;
}
.article .nav-dropdown-container {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
}
.article .nav-dropdown-active {
  z-index: 11;
}
.article .nav-dropdown-active .nav-dropdown-container {
  display: block;
  visibility: visible;
  opacity: 1;
}
.article .nav-dropdown-active .nav-dropdown-button-icon {
  color: var(--text-orange);
  transform: rotate(90deg);
}
.article .search-container {
  width: 25%;
  position: relative;
  z-index: 0;
}
@media (min-width: 1281px) and (max-width: 1440px) {
  .article .search-container {
    display: none;
  }
}
@media (min-width: 1160px) and (max-width: 1280px) {
  .article .search-container {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1160px) {
  .article .search-container {
    display: none;
  }
}
.article #searchButton {
  background: var(--button-bg-primary);
  outline: none;
  border: none;
}
.article #searchButton:hover {
  background: var(--whitegrey);
}
.article #searchButton:hover svg {
  fill: var(--button-bg-primary);
}
.article #ss360-custom-searchbox > button {
  background-color: var(--button-bg-primary);
  background: var(--text-primary);
}
@media (min-width: 1024px) {
  .article .nav-dropdown-container {
    width: auto;
    min-width: 192px;
  }
}
.article .nav-dropdown-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: var(--header-bg);
}
.article .nav-dropdown-menu .nav-dropdown-item, .article .nav-dropdown-menu li a {
  display: block;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: var(--text-primary);
  transition: all 0.2s ease-in-out;
}
.article .nav-dropdown-item:hover, .article .nav-dropdown-menu a:hover {
  color: var(--orange);
  text-decoration: none;
}
.article .site-nav .button {
  height: 45px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  padding: 12px 16px;
}
.article .nav-post-title {
  display: block;
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
  top: 20px;
  color: var(--text-primary);
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(175%);
}
.article .nav-post-title.dash {
  left: -25px;
}
.article .nav-post-title.dash:before {
  content: "– ";
  opacity: 0.5;
}
.article .nav-post-title-active .nav-post-title {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.article .site-nav-right {
  flex: 0 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  height: 76px;
  margin-right: -12px;
}
.article .site-nav-right .nav {
  position: relative;
  margin: 4px 0 0;
}
.article .social-links {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.article .social-link {
  display: inline-block;
  margin: 0;
  padding: 10px;
  opacity: 0.8;
}
.article .social-link:hover {
  opacity: 1;
}
.article .social-link svg {
  height: 1.8rem;
  fill: #fff;
}
.article .social-link-fb svg, .article .social-link-wb svg {
  height: 1.6rem;
}
.article .social-link-wb svg path {
  stroke: #fff;
}
.article .social-link-rss svg {
  height: 1.9rem;
}
.article .subscribe-button {
  display: block;
  margin: 0 0 0 10px;
  padding: 4px 10px;
  border: 1px solid #fff;
  color: #fff;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;
}
.article .subscribe-button:hover {
  text-decoration: none;
  opacity: 1;
}
.article .site-nav-right .nav + .subscribe-button {
  margin-left: 24px;
}
.article .rss-button {
  padding: 10px 8px;
  opacity: 0.8;
}
.article .rss-button:hover {
  opacity: 1;
}
.article .rss-button svg {
  margin-bottom: 1px;
  height: 2.1rem;
  fill: #fff;
}
.article .site-nav .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}
.article .site-nav .nav-button {
  display: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1004;
  background: 0 0;
  outline: none;
  transition: background 0.2s ease;
}
.article .site-nav .nav-button .navicon {
  background: var(--header-bg);
  display: block;
  width: 20px;
  height: 2px;
  margin: 3px auto;
  position: relative;
  transition: all 0.2s ease-out;
}
.article .site-nav .nav-button .navicon:after, .article .site-nav .nav-button .navicon:before {
  background: var(--header-bg);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.4s ease-out;
  width: 100%;
}
.article .site-nav .nav-button .navicon:before {
  top: 5px;
}
.article .site-nav .nav-button .navicon:after {
  top: -5px;
}
.article .site-nav .nav-button.active .navicon {
  background: 0 0;
}
.article .site-nav .nav-button.active .navicon:before {
  background: var(--text-primary);
  transform: rotate(45deg);
}
.article .site-nav .nav-button.active .navicon:after {
  background: var(--text-primary);
  transform: rotate(-45deg);
}
.article .site-nav .nav-button.active .navicon:after, .article .site-nav .nav-button.active .navicon:before {
  top: 0;
}
.article .site-nav-right .account-menu, .article .site-nav-right .button {
  margin-left: 3.6rem;
}
.article .account-menu {
  position: relative;
}
.article .account-menu-avatar {
  position: relative;
  display: block;
  margin: 0;
  padding: 1px;
  width: 32px;
  height: 32px;
  background: var(--orange);
  border-radius: 100%;
  cursor: pointer;
}
.article .account-menu-avatar img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100%;
}
.article .account-menu-dropdown {
  visibility: hidden;
  position: absolute;
  top: 90px;
  right: -8px;
  z-index: 9998;
  padding: 1rem;
  min-width: 240px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 26px rgba(39, 44, 49, 0.08), 1px 3px 8px rgba(39, 44, 49, 0.06);
  list-style: none;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translateY(-6px) scale(0.95);
  transform-origin: top right;
  pointer-events: none;
}
.article .account-menu-dropdown.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) scale(1);
  pointer-events: all;
}
.article .account-menu-dropdown:before {
  content: "";
  position: absolute;
  top: -8px;
  right: 14px;
  z-index: 9998;
  width: 0;
  height: 0;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
}
.article .account-menu-dropdown li {
  margin: 0;
  padding: 0;
}
.article .account-menu .account-info {
  display: flex;
  flex-direction: column;
  margin: 0 0 6px;
  padding: 4px 20px 12px;
  border-bottom: 1px solid var(--whitegrey);
  color: var(--midgrey);
}
.article .account-info .account-email {
  overflow-x: hidden;
  max-width: 200px;
  color: #303a3e;
  font-weight: 600;
  text-overflow: ellipsis;
}
.article .account-menu-dropdown a {
  display: block;
  padding: 6px 20px;
  color: #303a3e;
}
.article .account-menu-dropdown a:hover {
  color: var(--orange);
  text-decoration: none;
}
@media (max-width: 1023px) {
  .article .search-container {
    width: 80%;
    margin: 0 auto;
  }
  .article .site-home-header .site-nav {
    margin-left: -5vw;
  }
  .article .site-nav-main {
    padding-right: 0;
    padding-left: 0;
  }
  .article .site-nav-left .site-nav-content {
    display: none;
  }
  .article .site-nav-left {
    margin-right: 0;
    padding-left: 5vw;
    padding-bottom: 10px;
  }
  .article .site-nav-left-wrapper {
    z-index: 1001;
  }
  .article .site-nav-right-wrapper {
    display: none;
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 20px;
    width: 100%;
    border-radius: 20px;
    background: var(--header-bg);
    z-index: 1003;
  }
  .article .site-nav-right-wrapper.active {
    transform: translateY(0);
    display: block;
  }
  .article .site-nav-right {
    display: block;
    text-align: center;
    height: 100%;
    margin-right: 0;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .article .site-nav-right .nav {
    margin-top: 0;
  }
  .article .site-nav-right .account-menu, .article .site-nav-right .button {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .article .site-nav .button {
    height: 42px;
    font-size: 12px;
    line-height: 18px;
    padding: 12px 16px;
    width: 100%;
  }
  .article .nav {
    text-align: center;
  }
  .article .nav, .article .nav li {
    display: block;
  }
  .article .nav li {
    margin: 0;
  }
  .article .nav-item-icon {
    display: none;
  }
  .article .nav-item-icon-mobile {
    display: block;
  }
  .article .nav-post-title-active .nav {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  .article .site-nav {
    overflow: visible;
  }
  .article .site-nav .nav-button {
    display: block;
  }
  .article .site-nav-logo {
    padding: 0;
  }
  .article .site-nav-menu {
    flex-direction: column;
    align-items: stretch;
  }
  .article .site-nav-menu:after {
    content: "";
    display: block;
    height: 1px;
    margin-top: 8px;
    margin-bottom: 16px;
    background: #f6f6f6;
    width: 100%;
  }
  .article .nav-dropdown-button, .article .nav-dropdown-item, .article .nav-dropdown-menu a, .article .nav li a {
    color: var(--text-primary);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .article .nav-dropdown-item, .article .nav-dropdown-menu a {
    text-align: left;
  }
  .article .account-menu-avatar {
    margin: 0 auto;
  }
  .article .account-menu-dropdown {
    top: 250px;
    left: 12px;
    transform: translateX(-50%);
    width: calc(100% - 24px);
  }
  .article .account-menu-dropdown:before {
    content: none;
  }
}
.article .posts {
  padding-top: 5rem;
}
.article .post-feed {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.2rem;
}
@media (min-width: 992px) {
  .article .post-feed .post-card:not(.post-card-large) {
    max-width: 48%;
    max-width: calc(50% - 24px);
  }
}
.article .post-card {
  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 1.2rem 2.5rem;
  min-height: 220px;
  background-color: var(--card-bg);
  background-size: cover;
  border: 1px solid var(--whitegrey);
  box-shadow: 0 2px 7px rgba(105, 105, 105, 0.08);
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
}
.article .post-card-small {
  min-height: 0;
  margin: 1rem 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid var(--whitegrey-darker);
}
.article .post-card-small .post-card-content {
  padding: 1.6rem;
}
.article .post-card-small .post-card-title {
  margin: 1rem 0;
}
.article .post-card:hover {
  box-shadow: 0 4px 7px rgba(105, 105, 105, 0.28);
}
.article .top-stories-feed {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}
.article .top-stories-feed .post-card, .article .top-stories-feed .top-stories-feed-button {
  margin-left: 12px;
  margin-right: 12px;
}
.article .top-stories-feed .post-card {
  flex: 1 1 263px;
  flex-wrap: wrap;
}
.article .top-stories-feed .post-card-image {
  height: auto;
  min-height: 150px;
  max-height: 100%;
}
.article .top-stories-feed .post-card-large {
  flex: 1 1 100%;
  flex-direction: row;
}
.article .top-stories-feed .post-card-large .post-card-image-link {
  width: 100%;
}
.article .top-stories-feed .post-card-large .post-card-content {
  flex: 0 1 100%;
}
.article .top-stories-feed .post-card-large .post-card-content-link, .article .top-stories-feed .post-card-large .post-card-meta {
  padding-left: 0;
}
.article .featured-stories-feed .feed-title {
  margin: 0 0 0.9rem;
  padding: 2rem 2.5rem;
  text-transform: uppercase;
  border-bottom: 1px solid var(--whitegrey-darker);
}
.article .featured-stories-feed .post-card-small:last-child {
  border-bottom: none;
}
.article .post-card-image-link {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 3px;
}
.article .post-card-image {
  width: 100%;
  min-height: 200px;
  max-height: 250px;
  height: auto;
  background: var(--lightgrey) no-repeat 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.article .post-card-content-link {
  position: relative;
  display: block;
  color: var(--darkgrey);
}
.article .post-card-content-link:hover {
  text-decoration: none;
}
.article .post-card-header {
  margin: 0;
}
.article .post-feed .no-image .post-card-content-link {
  padding: 0;
}
.article .no-image .post-card-header {
  margin-top: 0;
}
.article .post-card-primary-tag {
  margin: 0 0 0.2em;
  color: var(--orange);
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: capitalize;
}
.article .post-card-title {
  margin: 0 0 1rem;
  line-height: 1.56;
  transition: color 0.2s ease-in-out;
}
.article .no-image .post-card-title {
  margin-top: 0;
}
.article .post-card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem 2.5rem;
}
.article .post-card-excerpt {
  max-width: 56em;
  color: var(--text-secondary);
  font-size: 1.4rem;
  line-height: 1.43;
}
.article .post-card-excerpt p {
  margin-bottom: 1.6rem;
}
.article .post-card-meta {
  display: flex;
  align-items: center;
  padding: 0;
}
.article .author-profile-image, .article .avatar-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  background: #fefefe;
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.article .post-card-meta .avatar-wrapper, .article .post-card-meta .profile-image-wrapper {
  position: relative;
}
.article .author-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 4px;
  padding: 0;
  list-style: none;
}
.article .author-list-item {
  position: relative;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
.article .static-avatar {
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  border-radius: 100%;
}
.article .author-name-tooltip {
  position: absolute;
  bottom: 105%;
  z-index: 999;
  display: block;
  padding: 2px 8px;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: 0.2px;
  white-space: nowrap;
  background: var(--darkgrey);
  border-radius: 3px;
  box-shadow: 0 12px 26px rgba(39, 44, 49, 0.08), 1px 3px 8px rgba(39, 44, 49, 0.03);
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translateY(6px);
  pointer-events: none;
}
.article .author-list-item:hover .author-name-tooltip {
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 767px) {
  .article .post-card-image {
    height: 216px;
  }
  .article .author-name-tooltip {
    display: none;
  }
}
.article .post-card-byline-content {
  flex: 1 1 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 2px 0 0 6px;
  color: var(--text-light);
  font-size: 1.2rem;
  line-height: 1.4em;
  font-weight: 500;
  letter-spacing: 0.2px;
}
.article .post-card-byline-content span {
  margin: 0 1rem 0 0;
}
.article .post-card-byline-content a {
  color: var(--text-primary);
}
.article .post-card-byline-date {
  font-size: 1.2rem;
}
.article .post-card-byline-date .bull {
  display: inline-block;
  margin: 0 4px;
  opacity: 0.6;
}
.article .single-author-byline {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  color: #5c7078;
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 500;
}
.article .single-author {
  display: flex;
  align-items: center;
}
.article .single-author .static-avatar {
  margin-left: -2px;
}
.article .single-author-name {
  display: inline-block;
}
@media (min-width: 768px) {
  .article .post-card-large {
    flex: 1 1 100%;
    flex-direction: row;
    min-height: 280px;
    border-top: 0;
  }
  .article .post-card-large:hover {
    border-bottom-color: #fefefe;
  }
  .article .post-card-large:not(.no-image) .post-card-header {
    margin-top: 0;
  }
  .article .post-card-large .post-card-image-link {
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
    min-height: 380px;
  }
  .article .post-card-large .post-card-image {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
  .article .post-card-large .post-card-content {
    flex: 0 1 361px;
    justify-content: center;
  }
  .article .post-card-large .post-card-title {
    margin-top: 0;
    font-size: 1.6rem;
  }
  .article .post-card-large .post-card-content-link, .article .post-card-large .post-card-meta {
    padding: 0 0 0 40px;
  }
  .article .post-card-large .post-card-excerpt p {
    margin-bottom: 1.5em;
    font-size: 1.4rem;
    line-height: 1.5em;
  }
}
@media (min-width: 1160px) {
  .article .post-card-large .post-card-image-link {
    min-height: 505px;
  }
}
@media (max-width: 991px) {
  .article .top-stories-feed .post-card {
    flex: 1 1 212px;
  }
  .article .top-stories-feed .post-card-large {
    flex: 1 1 100%;
  }
}
@media (max-width: 767px) {
  .article .post-feed {
    padding-top: 5vw;
  }
  .article .post-card {
    margin-bottom: 5vw;
  }
  .article .top-stories-feed .post-card:not(.post-card-large) {
    max-wdith: 100%;
  }
  .article .featured-stories-feed .feed-title {
    padding: 2rem 1.5rem;
  }
}
@media (max-width: 500px) {
  .article .post-card-title {
    font-size: 1.4rem;
  }
  .article .post-card-excerpt {
    font-size: 1.2rem;
  }
}
.article .page-template .site-main, .article .post-template .site-main {
  padding-bottom: 60px;
  background: #fff;
}
.article .post-full-header {
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.article .post-full-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--midgrey);
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
}
.article .post-full-meta-date {
  color: var(--midgrey);
  font-size: 1.2rem;
  font-weight: 400;
}
.article .post-full-header-no-padding {
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 1280px) {
  .article .post-full-header {
    padding: 60px 11vw 50px;
  }
  .article .post-full-header-no-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 991px) {
  .article .post-full-header {
    padding-right: 5vw;
    padding-left: 5vw;
  }
  .article .post-full-header-no-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 500px) {
  .article .post-full-header {
    padding: 20px 0 35px;
  }
}
.article .post-full-title {
  margin-bottom: 0.2em;
  color: #0a0b0c;
}
.article .post-full-title-smaller {
  font-size: 3.6rem;
  margin-bottom: 0;
}
.article .post-full-custom-excerpt {
  margin: 20px 0 0;
  color: var(--midgrey);
  line-height: 1.4em;
  font-weight: 300;
  font-size: 16px;
}
.article .date-divider {
  display: inline-block;
  margin: 0 6px 1px;
  font-weight: 300;
}
.article .tag-hash-hide-feature-image .post-full-image {
  display: none;
}
.article .post-full-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin: 25px 0 50px;
  background: #fefefe;
  border-radius: 3px;
}
.article .post-full-image img {
  max-width: var(--container-width);
  width: 100%;
  height: auto;
}
.article .post-full-content {
  position: relative;
  margin: 0 auto;
  padding: 0 170px;
  min-height: 230px;
  font-size: 2rem;
  line-height: 1.6em;
  background: #fff;
}
.article .post-full-content-no-padding {
  padding: 0 15px;
}
@media (max-width: 1280px) {
  .article .post-full-content {
    padding: 0 11vw;
  }
  .article .post-full-content-no-padding {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  .article .post-full-content {
    padding: 0 5vw;
    font-size: 1.8rem;
  }
}
@media (max-width: 500px) {
  .article .post-full-custom-excerpt {
    font-size: 1.9rem;
    line-height: 1.5em;
  }
}
.article .no-image .post-full-content {
  padding-top: 0;
}
.article .no-image .post-full-content:after, .article .no-image .post-full-content:before {
  display: none;
}
.article .footnotes, .article .post-full-comments, .article .post-full-content blockquote, .article .post-full-content dl, .article .post-full-content h1, .article .post-full-content h2, .article .post-full-content h3, .article .post-full-content h4, .article .post-full-content h5, .article .post-full-content h6, .article .post-full-content ol, .article .post-full-content p, .article .post-full-content pre, .article .post-full-content ul {
  margin: 0 0 1.5em;
  min-width: 100%;
}
@media (max-width: 500px) {
  .article .footnotes, .article .post-full-comments, .article .post-full-content dl, .article .post-full-content ol, .article .post-full-content p, .article .post-full-content pre, .article .post-full-content ul {
    margin-bottom: 1.28em;
  }
}
.article .post-full-content li {
  word-break: break-word;
}
.article .post-full-content li p {
  margin: 0;
}
.article .post-full-content a:not(.button):not(.card):not(.kg-bookmark-container) {
  color: var(--darkgrey);
  word-break: break-word;
  box-shadow: var(--darkgrey) 0 -1px 0 inset;
  transition: all 0.2s ease-in-out;
}
.article .post-full-content a:not(.button):not(.card):not(.kg-bookmark-container):hover {
  color: var(--orange);
  text-decoration: none;
  box-shadow: var(--orange) 0 -1px 0 inset;
}
.article .post-full-content .subscribe-form {
  flex-direction: column;
  text-align: center;
  margin: 2rem auto 3rem;
  padding: 2.5rem 0 3.5rem;
  width: 100%;
  border: 0 solid var(--lightgrey);
  border-width: 1px 0;
}
.article .post-full-content .subscribe-form h3 {
  font-weight: 400;
  margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
  .article .post-full-content .subscribe-form {
    margin: 1.5rem auto 2.5rem;
    padding: 2rem 0 3rem;
  }
  .article .post-full-content .subscribe-form h3 {
    font-size: 2.1rem;
  }
}
.article .post-full-content em, .article .post-full-content strong {
  color: var(--text-primary);
}
.article .post-full-content small {
  display: inline-block;
  line-height: 1.6em;
}
.article .post-full-content li:first-child {
  margin-top: 0;
}
.article .post-full-content img, .article .post-full-content video {
  display: block;
  margin: 1.5em auto;
  max-width: var(--container-width);
  height: auto;
}
@media (max-width: 1160px) {
  .article .post-full-content img, .article .post-full-content video {
    width: 100%;
  }
}
.article .post-full-content img[src$="#full"] {
  max-width: none;
  width: 100vw;
}
.article .post-full-content img + br + small {
  display: block;
  margin-top: -3em;
  margin-bottom: 1.5em;
  text-align: center;
}
.article .post-full-content iframe {
  margin: 0 auto !important;
  width: 624px;
  height: 350px;
}

.article .post-full-content blockquote {
  margin: 0 0 1.5em;
  padding: 0 1.5em;
  border-left: 3px solid #f63;
}
@media (max-width: 500px) {
  .article .post-full-content blockquote {
    padding: 0 1.3em;
  }
}
.article .post-full-content blockquote p {
  margin: 0 0 1em;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: italic;
}
.article .post-full-content blockquote p:last-child {
  margin-bottom: 0;
}
.article .post-full-content code {
  padding: 0 5px 2px;
  font-size: 0.8em;
  line-height: 1em;
  font-weight: 400 !important;
  background: var(--whitegrey);
  border-radius: 3px;
}
.article .post-full-content p code {
  word-break: break-all;
}
.article .post-full-content pre {
  overflow-x: auto;
  margin: 1.5em 0 3em;
  padding: 20px;
  max-width: 100%;
  border: 1px solid #000;
  color: var(--whitegrey);
  font-size: 1.4rem;
  line-height: 1.5em;
  background: #0e1012;
  border-radius: 5px;
}
.article .post-full-content pre ::-moz-selection {
  color: #3b474d;
}
.article .post-full-content pre ::selection {
  color: #3b474d;
}
.article .post-full-content pre code {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  background: 0 0;
}
.article .post-full-content pre code :not(span) {
  color: inherit;
}
.article .post-full-content .fluid-width-video-wrapper {
  margin: 1.5em 0 3em;
}
.article .post-full-content hr {
  margin: 2em 0;
}
.article .post-full-content hr:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  display: block;
  margin-left: -10px;
  width: 1px;
  height: 30px;
  background: var(--lightgrey);
  box-shadow: 0 0 0 5px #fff;
  transform: rotate(45deg);
}
.article .post-full-content hr + p {
  margin-top: 1.2em;
}
.article .post-full-content h1, .article .post-full-content h2, .article .post-full-content h3, .article .post-full-content h4, .article .post-full-content h5, .article .post-full-content h6 {
  color: #0a0b0c;
}
.article .post-full-content h1 {
  margin: 0.5em 0 0.4em;
  font-weight: 600;
}
.article .post-full-content p + h1 {
  margin-top: 0.8em;
}
.article .post-full-content h2 {
  margin: 0.5em 0 0.4em;
  font-size: 3.2rem;
  line-height: 1.25em;
  font-weight: 600;
}
.article .post-full-content p + h2 {
  margin-top: 0.8em;
}
@media (max-width: 991px) {
  .article .post-full-content h2 {
    margin-bottom: 0.3em;
    font-size: 2.8rem;
    line-height: 1.25em;
  }
}
.article .post-full-content h3 {
  margin: 0.5em 0 0.2em;
  font-size: 2.5rem;
  line-height: 1.3em;
  font-weight: 600;
}
.article .post-full-content h2 + h3 {
  margin-top: 0.7em;
}
@media (max-width: 991px) {
  .article .post-full-content h3 {
    margin-bottom: 0.3em;
    font-size: 2.4rem;
    line-height: 1.3em;
  }
}
.article .post-full-content h4 {
  margin: 0.5em 0 0.2em;
  font-size: 2.5rem;
  font-weight: 600;
}
.article .post-full-content h2 + h4 {
  margin-top: 0.7em;
}
.article .post-full-content h3 + h4 {
  margin-top: 0;
}
@media (max-width: 991px) {
  .article .post-full-content h4 {
    margin-bottom: 0.3em;
    font-size: 2.4rem;
    line-height: 1.3em;
  }
}
.article .post-full-content h5 {
  display: block;
  margin: 0.5em 0;
  padding: 0.4em 1em 0.9em;
  border: 0;
  color: var(--orange);
  font-size: 3.2rem;
  line-height: 1.35em;
  text-align: center;
}
@media (min-width: 1280px) {
  .article .post-full-content h5 {
    max-width: 1060px;
    width: 100vw;
  }
}
@media (max-width: 991px) {
  .article .post-full-content h5 {
    margin-bottom: 1em;
    margin-left: 1.3em;
    padding: 0 0 0.5em;
    font-size: 2.4rem;
    text-align: initial;
  }
}
.article .post-full-content h6 {
  margin: 0.5em 0 0.2em;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 991px) {
  .article .post-full-content h6 {
    font-size: 1.8rem;
  }
}
.article .footnotes-sep {
  margin-bottom: 30px;
}
.article .footnotes {
  font-size: 1.5rem;
}
.article .footnotes p {
  margin: 0;
}
.article .footnote-backref {
  color: var(--orange) !important;
  font-size: 1.2rem;
  font-weight: 700;
  text-decoration: none !important;
  box-shadow: none !important;
}
@media (max-width: 1280px) {
  .article .post-full-image {
    margin: 25px -6vw 50px;
    border-radius: 0;
  }
  .article .post-full-image img {
    max-width: var(--viewport-xl);
  }
}
@media (max-width: 767px) {
  .article .post-full-image {
    margin: 25px -5vw;
  }
}
@media (max-width: 500px) {
  .article .post-full-meta {
    font-size: 1.2rem;
    line-height: 1.3em;
  }
  .article .post-full-title {
    margin-top: 0.2em;
    line-height: 1.05em;
  }
  .article .post-full-image {
    margin-top: 5px;
    margin-bottom: 5vw;
  }
  .article .post-full-content {
    padding: 0;
  }
  .article .post-full-content:after, .article .post-full-content:before {
    display: none;
  }
}
.article .post-full-content table {
  display: inline-block;
  overflow-x: auto;
  margin: 0.5em 0 2.5em;
  max-width: 100%;
  width: auto;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 1.6rem;
  white-space: nowrap;
  vertical-align: top;
  -webkit-overflow-scrolling: touch;
  background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0, transparent 75%) 0, radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0, transparent 75%) 100%;
  background-attachment: scroll, scroll;
  background-size: 10px 100%, 10px 100%;
  background-repeat: no-repeat;
}
.article .post-full-content table td:first-child {
  background-image: linear-gradient(90deg, #fff 50%, rgba(255, 255, 255, 0));
  background-size: 20px 100%;
  background-repeat: no-repeat;
}
.article .post-full-content table td:last-child {
  background-image: linear-gradient(270deg, #fff 50%, rgba(255, 255, 255, 0));
  background-position: 100% 0;
  background-size: 20px 100%;
  background-repeat: no-repeat;
}
.article .post-full-content table th {
  color: var(--darkgrey);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;
  text-transform: uppercase;
  background-color: #fff;
}
.article .post-full-content table td, .article .post-full-content table th {
  padding: 6px 12px;
  border: 1px solid #f7f7f7;
}
.article .post-full-byline {
  display: flex;
  justify-content: space-between;
  margin: 35px 0 0;
  padding-top: 15px;
  border-top: 1px solid #fefefe;
}
.article .post-full-byline-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.article .post-full-byline-content .author-list {
  justify-content: flex-start;
  padding: 0 12px 0 0;
}
.article .post-full-byline-meta {
  margin: 2px 0 0;
  color: #90a2aa;
  font-size: 1.2rem;
  line-height: 1.2em;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.article .post-full-byline-meta h4 {
  margin: 0 0 3px;
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 500;
}
.article .post-full-byline-meta h4 a {
  color: #2c3036;
}
.article .post-full-byline-meta h4 a:hover {
  color: var(--darkgrey);
}
.article .post-full-byline-meta .bull {
  display: inline-block;
  margin: 0 4px;
  opacity: 0.6;
}
.article .author-avatar {
  display: block;
  overflow: hidden;
  margin: 0 -4px;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 100%;
  transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.7s;
}
.article .author-list-item .author-card {
  position: absolute;
  bottom: 130%;
  left: 50%;
  z-index: 600;
  display: flex;
  justify-content: space-between;
  margin-left: -200px;
  width: 400px;
  font-size: 1.4rem;
  line-height: 1.5em;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 12px 26px rgba(39, 44, 49, 0.08), 1px 3px 8px rgba(39, 44, 49, 0.06);
  opacity: 0;
  transition: all 0.35s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: scale(0.98) translateY(15px);
  pointer-events: none;
}
.article .author-list-item .author-card:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  display: block;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.article .author-list-item .author-card.hovered {
  opacity: 1;
  transform: scale(1) translateY(0);
  pointer-events: auto;
}
.article .author-card {
  padding: 20px 20px 22px;
}
.article .author-card .author-info {
  flex: 1 1 auto;
  padding: 0 0 0 20px;
}
.article .author-card .author-info h2 {
  margin: 8px 0 0;
  font-size: 1.6rem;
}
.article .author-card .author-info p {
  margin: 4px 0 0;
  color: #5c7078;
}
.article .author-card .author-info .bio h2 {
  margin-top: 0;
}
.article .author-card .author-info .bio p {
  margin-top: 0.8em;
}
.article .author-card .author-profile-image {
  flex: 0 0 60px;
  margin: 0;
  width: 60px;
  height: 60px;
  border: none;
}
.article .basic-info .avatar-wrapper {
  position: relative;
  margin: 0;
  width: 60px;
  height: 60px;
  border: none;
  background: rgba(229, 239, 245, 0.1);
}
.article .basic-info .avatar-wrapper svg {
  margin: 0;
  width: 60px;
  height: 60px;
  opacity: 0.15;
}
@media (max-width: 1280px) {
  .article .author-list-item .author-card {
    margin-left: -50px;
    width: 430px;
  }
  .article .author-list-item .author-card:before {
    left: 50px;
  }
}
@media (max-width: 650px) {
  .article .author-list-item .author-card {
    display: none;
  }
}
@media (max-width: 500px) {
  .article .author-avatar {
    width: 36px;
    height: 36px;
  }
  .article .post-full-byline {
    margin-top: 20px;
  }
  .article .post-full-byline-meta {
    font-size: 1.2rem;
  }
  .article .post-full-byline-meta h4 {
    margin-bottom: 2px;
    font-size: 1.2rem;
  }
}
.article .post-full-comments {
  margin: 0 auto;
  max-width: 840px;
}
.article .read-next {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--orange);
}
.article .read-next-title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 2rem;
  padding: 0 12px;
  color: #fff;
  font-weight: 600;
}
.article .read-next-feed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
  padding: 50px 0 35px;
}
.article .read-next .post-card {
  max-width: 368px;
  max-width: calc(50% - 2.4rem);
  padding-bottom: 0;
  border-left-color: var(--orange);
  border-bottom: none;
  border-bottom-color: var(--orange);
  border-right-color: var(--orange);
  border-top-color: var(--orange);
  box-shadow: 0 4px 7px rgba(89, 89, 89, 0.6);
}
.article .read-next .post-card:hover {
  box-shadow: 0 6px 7px rgba(89, 89, 89, 0.8);
}
@media (min-width: 992px) and (max-width: 1160px) {
  .article .read-next .post-card {
    flex: 1 1 298px;
  }
}
@media (max-width: 767px) {
  .article .read-next .post-card {
    max-width: 100%;
  }
}
.article .read-next .post-card:after {
  display: none;
}
.article .read-next .post-card-primary-tag {
  opacity: 0.6;
}
.article .read-next .post-card-title {
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
}
.article .read-next .post-card:hover .post-card-image {
  opacity: 1;
}
.article .read-next-card {
  position: relative;
  flex: 0 1 326px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 25px 50px;
  padding: 25px;
  background: linear-gradient(#1a1c20, #0a0b0c);
  border-radius: 3px;
}
.article .read-next-card a {
  transition: all 0.2s ease-in-out;
}
.article .read-next-card a:hover {
  text-decoration: none;
}
.article .read-next-card-header h3 {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;
  line-height: 1em;
  font-weight: 300;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.article .read-next-card-header h3 a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  opacity: 0.8;
}
.article .read-next-card-header h3 a:hover {
  opacity: 1;
}
.article .read-next-card-content {
  font-size: 1.7rem;
}
.article .read-next-card-content ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}
.article .read-next-card-content li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 20px 0;
  border-bottom: rgba(255, 255, 255, 0.1);
}
.article .read-next-card-content li:last-of-type {
  padding-bottom: 5px;
  border: none;
}
.article .read-next-card-content h4 {
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.35em;
  font-weight: 600;
}
.article .read-next-card-content li a {
  display: block;
  color: #fff;
  opacity: 0.8;
}
.article .read-next-card-content li a:hover {
  opacity: 1;
}
.article .read-next-card-excerpt {
  overflow: hidden;
  max-width: 100%;
  font-size: 1.4rem;
  line-height: 1.2em;
  text-overflow: ellipsis;
}
.article .read-next-card-meta {
  margin-top: 2px;
  font-size: 1.2rem;
  line-height: 1.4em;
  font-weight: 400;
}
.article .read-next-card-meta p {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
}
.article .read-next-card-footer {
  position: relative;
  margin: 40px 0 5px;
}
.article .read-next-card-footer a {
  padding: 7px 12px 8px 14px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.3rem;
  border-radius: 999px;
  transition: all 0.35s ease-in-out;
}
.article .read-next-card-footer a:hover {
  border-color: var(--yellow);
  color: var(--yellow);
  text-decoration: none;
}
@media (max-width: 1280px) {
  .article .read-next-card {
    flex: 1 1 261px;
    margin-bottom: 5vw;
  }
}
@media (max-width: 650px) {
  .article .read-next-feed {
    flex-direction: column;
    padding: 25px 0 0;
  }
  .article .read-next-card {
    flex: 1 1 auto;
    margin: 0 25px;
    padding: 0;
    background: 0 0;
  }
  .article .read-next .post-card {
    flex: 1 1 auto;
    margin: 25px;
    padding: 25px 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.article .post-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article .post-full-content .kg-image {
  margin: 0 auto;
  max-width: 100%;
}
.article .post-full-image + .post-full-content .kg-content :first-child .kg-image {
  width: 100%;
}
.article .post-full-content .kg-width-wide .kg-image {
  max-width: var(--container-width);
}
.article .post-full-content .kg-width-full .kg-image {
  max-width: 100vw;
}
.article .post-full-content figure {
  margin: 0.8em 0 2.3em;
  width: 720px
}
.article .post-full-content h1 + figure, .article .post-full-content h2 + figure, .article .post-full-content h3 + figure, .article .post-full-content h4 + figure {
  margin-top: 2em;
}
.article .post-full-content figure img {
  margin: 0;
}
.article .post-full-content figcaption {
  margin: 1em auto 0;
  color: #5c7078;
  font-size: 75%;
  line-height: 1.5em;
  text-align: center;
  max-width: var(--container-width);
}
.article .kg-width-full figcaption {
  padding: 0 1.5em;
}
.article .kg-embed-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.article .kg-embed-card .fluid-width-video-wrapper {
  margin: 0;
}
@media (max-width: 1160px) {
  .article .post-full-content .kg-width-full .kg-image {
    width: 100vw;
  }
}
.article .kg-gallery-container {
  display: flex;
  flex-direction: column;
  max-width: var(--container-width);
  width: 100vw;
}
.article .kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.article .kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}
.article .kg-gallery-row:not(:first-of-type) {
  margin: 0.75em 0 0;
}
.article .kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 0.75em;
}
.article .kg-gallery-card + .kg-gallery-card, .article .kg-gallery-card + .kg-image-card.kg-width-wide, .article .kg-image-card.kg-width-wide + .kg-gallery-card, .article .kg-image-card.kg-width-wide + .kg-image-card.kg-width-wide {
  margin: -2.25em 0 3em;
}
.article .kg-code-card {
  width: 100%;
}
.article .kg-code-card pre {
  margin: 0;
}
.article .kg-bookmark-card {
  width: 100%;
}
.article .kg-card + .kg-bookmark-card {
  margin-top: 0;
}
.article .post-full-content .kg-bookmark-container {
  display: flex;
  min-height: 148px;
  border-radius: 3px;
}
.article .post-full-content .kg-bookmark-container, .article .post-full-content .kg-bookmark-container:hover {
  color: var(--darkgrey);
  text-decoration: none;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
}
.article .kg-bookmark-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}
.article .kg-bookmark-title {
  color: #303a3e;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 600;
  transition: color 0.2s ease-in-out;
}
.article .post-full-content .kg-bookmark-container:hover .kg-bookmark-title {
  color: var(--orange);
}
.article .kg-bookmark-description {
  display: -webkit-box;
  overflow-y: hidden;
  margin-top: 12px;
  max-height: 48px;
  color: #5c7078;
  font-size: 1.5rem;
  line-height: 1.5em;
  font-weight: 400;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article .kg-bookmark-thumbnail {
  position: relative;
  min-width: 33%;
  max-height: 100%;
}
.article .kg-bookmark-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 3px 3px 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.article .kg-bookmark-metadata {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 14px;
  color: #5c7078;
  font-size: 1.5rem;
  font-weight: 400;
}
.article .post-full-content .kg-bookmark-icon {
  margin-right: 8px;
  width: 22px;
  height: 22px;
}
.article .kg-bookmark-author {
  line-height: 1.5em;
}
.article .kg-bookmark-author:after {
  content: "•";
  margin: 0 6px;
}
.article .kg-bookmark-publisher {
  overflow: hidden;
  max-width: 240px;
  line-height: 1.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .article .post-full-content figure {
    margin: 0.2em 0 1.3em;
  }
  .article .post-full-content h1 + figure, .article .post-full-content h2 + figure, .article .post-full-content h3 + figure, .article .post-full-content h4 + figure {
    margin-top: 0.9em;
  }
}
@media (max-width: 500px) {
  .article .post-full-content .kg-width-full, .article .post-full-content .kg-width-wide {
    margin-right: -5vw;
    margin-left: -5vw;
  }
  .article .post-full-content figcaption {
    margin-bottom: 0.4em;
  }
  .article .post-full-content .kg-bookmark-container {
    flex-direction: column;
  }
  .article .kg-bookmark-description, .article .kg-bookmark-metadata, .article .kg-bookmark-title {
    font-size: 1.4rem;
    line-height: 1.5em;
  }
  .article .post-full-content .kg-bookmark-icon {
    width: 18px;
    height: 18px;
  }
  .article .kg-bookmark-thumbnail {
    order: 1;
    min-height: 160px;
    width: 100%;
  }
  .article .kg-bookmark-thumbnail img {
    border-radius: 3px 3px 0 0;
  }
  .article .kg-bookmark-content {
    order: 2;
  }
}
.article .post-two-columns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10rem auto 6rem;
}
.article .post-two-columns .excerpt-mobile, .article .post-two-columns .title-mobile {
  display: none;
}
.article .post-two-columns .image-column, .article .post-two-columns .post-column {
  padding: 0 5rem;
}
.article .post-two-columns .image-column {
  width: 42%;
}
.article .post-two-columns .post-column {
  width: 58%;
}
.article .post-two-columns .image {
  max-width: 100%;
  width: auto;
  filter: drop-shadow(-14px 14px 20px rgba(0, 0, 0, 0.25));
}
.article .post-two-columns h1 {
  font-size: 3.2rem;
}
.article .post-two-columns .excerpt {
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 600;
  color: var(--orange);
}
.article .post-two-columns .post-full-content {
  margin-top: 2rem;
  padding: 0;
  font-size: 1.6rem;
}
@media (max-width: 1160px) {
  .article .post-two-columns .post-column {
    width: 50%;
    padding: 0 3rem;
  }
  .article .post-two-columns .image-column {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .article .post-two-columns {
    margin: 4rem auto 2rem;
    flex-direction: column;
    align-items: start;
  }
  .article .post-two-columns .title-mobile {
    margin-bottom: 2rem;
    display: block;
    font-size: 32px;
  }
  .article .post-two-columns .excerpt-mobile {
    margin-bottom: 0.5rem;
    display: block;
  }
  .article .post-two-columns .excerpt-desktop, .article .post-two-columns .title-desktop {
    display: none;
  }
  .article .post-two-columns .image-column, .article .post-two-columns .post-column {
    width: 100%;
    padding: 1.5rem 0;
  }
  .article .post-two-columns .image {
    max-width: 400px;
  }
  .article .post-two-columns .post-full-content {
    font-size: 1.6rem;
  }
}
.article .author-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10vw 0 10px;
}
.article .site-archive-header .author-header {
  align-items: center;
}
.article .site-archive-header .no-image .author-header {
  padding-bottom: 20px;
}
.article .author-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 0 0 30px;
}
.article .site-header-content .author-profile-image {
  z-index: 10;
  flex-shrink: 0;
  margin: -4px 0 0;
  width: 110px;
  height: 110px;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.1);
}
.article .author-header-content .author-bio {
  z-index: 10;
  flex-shrink: 0;
  margin: 6px 0 0;
  max-width: 46em;
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 400;
  opacity: 0.8;
}
.article .author-header-content .author-meta {
  z-index: 10;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 0 0 0 1px;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  white-space: nowrap;
}
.article .author-header-content .social-link:first-of-type {
  padding-left: 4px;
}
.article .no-image .author-header-content .author-bio, .article .no-image .author-header-content .author-meta {
  color: var(--midgrey);
  opacity: 1;
}
.article .author-social-link a {
  color: #fff;
  font-weight: 600;
}
.article .no-image .author-social-link a {
  color: var(--darkgrey);
}
.article .author-social-link a:hover {
  opacity: 1;
}
.article .author-social-link {
  display: inline-block;
  margin: 0;
  padding: 6px 0;
}
.article .author-location + .author-stats:before, .article .author-social-link + .author-social-link:before, .article .author-stats + .author-social-link:before {
  content: "\2022";
  display: inline-block;
  margin: 0 12px;
  color: #fff;
  opacity: 0.6;
}
.article .no-image .author-location + .author-stats:before, .article .no-image .author-social-link + .author-social-link:before, .article .no-image .author-stats + .author-social-link:before {
  color: var(--midgrey);
}
@media (max-width: 767px) {
  .article .author-location, .article .author-stats, .article .author-stats + .author-social-link:first-of-type:before {
    display: none;
  }
}
@media (max-width: 500px) {
  .article .author-header {
    padding: 10px 0 0;
  }
  .article .no-image .author-header {
    padding-bottom: 10px;
  }
  .article .author-header-content {
    align-items: center;
    margin: 16px 0 0;
  }
  .article .site-header-content .author-profile-image {
    width: 96px;
    height: 96px;
  }
  .article .author-header-content .author-bio {
    font-size: 1.8rem;
    line-height: 1.3em;
    letter-spacing: 0;
    text-align: center;
  }
  .article .author-header-content .author-meta {
    margin-top: 8px;
  }
  .article .author-location + .author-stats:before, .article .author-social-link + .author-social-link:before, .article .author-stats + .author-social-link:before {
    display: inline;
    margin: 0 6px;
  }
}
.article .error-content {
  padding: 14vw 4vw 6vw;
}
.article .site-nav-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  text-align: center;
}
.article .site-nav-center .site-nav-logo {
  margin-right: 0;
}
.article .error-message {
  padding-bottom: 10vw;
  border-bottom: 1px solid #fefefe;
  text-align: center;
}
.article .error-code {
  margin: 0;
  color: var(--lightgrey);
  font-size: 12vw;
  line-height: 1em;
  letter-spacing: -5px;
  opacity: 0.75;
}
.article .error-description {
  margin: 0;
  color: var(--midgrey);
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 400;
}
.article .error-link {
  display: inline-block;
  margin-top: 5px;
}
@media (min-width: 940px) {
  .article .error-content .post-card {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}
@media (max-width: 991px) {
  .article .error-content {
    padding-top: 24vw;
  }
  .article .error-code {
    font-size: 11.2rem;
  }
  .article .error-message {
    padding-bottom: 16vw;
  }
  .article .error-description {
    margin: 5px 0 0;
    font-size: 1.8rem;
  }
}
@media (max-width: 500px) {
  .article .error-content {
    padding-top: 28vw;
  }
  .article .error-message {
    padding-bottom: 14vw;
  }
}
.article .notification {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9000;
  padding: 22px 60px;
  color: #fff;
  font-size: 1.6rem;
  line-height: 1.3em;
  font-weight: 500;
  text-align: center;
  background: var(--green);
  transition: all 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  transform: translateY(-175%);
}
.article .signin-failure .notification-signin-failure, .article .signin-success .notification-signin, .article .signup-success .notification-signup, .article .subscribe-success .notification-subscribe {
  visibility: visible;
  transform: translateY(76px);
}
.article .signin-failure .notification-signin-failure {
  background: var(--red);
}
.article .billing-success .notification-billing-update, .article .checkout-success .notification-checkout {
  visibility: visible;
  transform: translateY(76px);
}
.article .billing-cancel .notification-billing-update-cancel {
  visibility: visible;
  background: var(--red);
  transform: translateY(76px);
}
.article .notification.closed {
  visibility: hidden;
  transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(-175%);
}

.article input[type=email], .article input[type=password], .article input[type=search], .article input[type=tel], .article input[type=text], .article input[type=url], .article select {
  display: block;
  padding: 0 0.6em;
  width: 100%;
  height: 44px;
  outline: 0;
  border: none;
  border: 1px solid var(--lightgrey);
  color: inherit;
  text-decoration: none;
  background: #fff;
  border-radius: 5px;
}
.article input[type=email]:focus, .article input[type=password]:focus, .article input[type=search]:focus, .article input[type=tel]:focus, .article input[type=text]:focus, .article input[type=url]:focus, .article select:focus, .article textarea:focus {
  border-color: var(--orange);
}
.article .site-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.article .signup-form {
  margin: 0 auto 10vw;
  max-width: 500px;
  width: 100%;
  text-align: center;
}
.article .signup-title {
  font-size: 4.2rem;
}
.article .signup-form p {
  color: var(--text-secondary);
}
.article .signup-form p small {
  display: inline-block;
  margin: 15px 0 0;
  font-size: 1.4rem;
}
.article .signup-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article .signup-box .button {
  margin-left: 10px;
}
@media (max-width: 600px) {
  .article .signup-box {
    flex-direction: column;
  }
  .article .signup-box .button {
    margin: 15px 0 0;
    width: 100%;
  }
}
.article .site-account, .article .site-signin, .article .site-signup {
  margin-top: 6rem;
}
.article .site-signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.article .signin-form {
  margin: 0 auto 10vw;
  max-width: 500px;
  width: 100%;
  text-align: center;
}
.article .signin-title {
  margin: 0 0 0.1em;
  font-size: 4.2rem;
}
.article .signin-form p {
  color: var(--midgrey);
  font-size: 1.3em;
}
.article .signin-form p small {
  display: inline-block;
  margin: 15px 0 0;
  font-size: 1.4rem;
}
.article .signin-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article .signin-box .button {
  margin-left: 10px;
}
@media (max-width: 600px) {
  .article .signin-box {
    flex-direction: column;
  }
  .article .signin-box .button {
    margin: 15px 0 0;
    width: 100%;
  }
}
.article .checkout-form {
  margin: 0 auto 10vw;
  max-width: 700px;
  width: 100%;
  text-align: center;
}
.article .checkout-title {
  margin: 0 auto 10px;
  padding: 5vw 0 0;
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: -0.6px;
}
.article .checkout-form p {
  margin: 0 auto 60px;
  max-width: 500px;
  color: var(--midgrey);
  font-size: 1.3em;
}
.article .checkout-box {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.article .checkout-plan {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-top: 6px solid var(--orange);
  color: var(--midgrey);
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(39, 44, 49, 0.03), 0 0 3px rgba(39, 44, 49, 0.05), 0 5px 22px -10px rgba(39, 44, 49, 0.12), 0 25px 30px -25px rgba(39, 44, 49, 0.05);
}
.article .checkout-plan + .checkout-plan {
  margin-left: 30px;
}
.article .checkout-plan-header {
  position: relative;
  padding: 0 0 50px;
  color: var(--midgrey);
  text-align: center;
}
.article .checkout-plan-header:after {
  content: "";
  position: absolute;
  right: 30%;
  bottom: 25px;
  left: 30%;
  display: block;
  height: 2px;
  background: rgba(39, 44, 49, 0.08);
  border-radius: 2px;
}
.article .checkout-plan-header h3 {
  margin: 0 0 25px;
  color: var(--darkgrey);
  font-size: 1.8rem;
  line-height: 1.15em;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.article .checkout-plan-header span {
  font-size: 2.6rem;
  font-weight: 300;
}
.article .checkout-plan-header strong {
  color: var(--darkgrey);
  font-size: 4.5rem;
  font-weight: 500;
}
.article .checkout-plan-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.article .checkout-plan-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.article .checkout-plan-content li {
  position: relative;
  margin: 0;
  padding: 0 0 15px 20px;
  line-height: 1.4em;
}
.article .checkout-plan-content li:before {
  content: "✓";
  position: absolute;
  left: 0;
  display: block;
  color: var(--green);
  font-size: 1.6rem;
  font-weight: 600;
}
.article .checkout-plan-content .button {
  margin: 20px 0 0;
}
@media (max-width: 740px) {
  .article .checkout-title {
    margin-top: 50px;
  }
  .article .checkout-box {
    flex-direction: column;
  }
  .article .checkout-plan + .checkout-plan {
    margin: 5vw 0 0;
  }
}
.article form.aweber-subscribe-form .button-loader, .article form.aweber-subscribe-form .message-error, .article form.aweber-subscribe-form .message-success, .article form[data-members-form] .button-loader, .article form[data-members-form] .message-error, .article form[data-members-form] .message-success {
  display: none;
}
.article form.aweber-subscribe-form.error .message-error, .article form.aweber-subscribe-form.success .message-success, .article form[data-members-form].error .message-error, .article form[data-members-form].success .message-success {
  position: fixed;
  top: 84px;
  right: 20px;
  left: 20px;
  z-index: 9999;
  margin: 0 auto;
  padding: 10px 0;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.5em;
  font-weight: 500;
  text-align: center;
  background: var(--green);
  border-radius: 5px;
}
.article form.aweber-subscribe-form.success .message-success, .article form[data-members-form].success .message-success {
  display: block;
  background: var(--green);
}
.article form.aweber-subscribe-form.error .message-error, .article form[data-members-form].error .message-error {
  display: block;
  color: var(--red);
  background: #fff;
  box-shadow: var(--red) 0 0 0 1px;
}
.article form.aweber-subscribe-form .button, .article form[data-members-form] .button {
  position: relative;
}
.article form.aweber-subscribe-form.loading .button-content, .article form[data-members-form].loading .button-content {
  visibility: hidden;
}
.article form.aweber-subscribe-form.loading .button-loader, .article form[data-members-form].loading .button-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  transform: translateY(-50%) translateX(-50%) scale(0.7);
}
.article .message-error svg, .article .message-success svg {
  position: relative;
  top: -1px;
  margin-right: 5px;
  width: 15px;
  height: 15px;
}
.article .message-success svg {
  fill: #fff;
}
.article .message-error svg {
  fill: var(--red);
}
.article .button-loader svg path, .article .button-loader svg rect {
  fill: #fff;
}
.article .site-account {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article .subscriber-box {
  margin: 0 auto;
  max-width: 560px;
  width: 100%;
}
.article .subscriber-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.article .subscription-title h1 {
  margin-bottom: 12px;
  font-size: 3.4rem;
}
.article .subscriber-avatar {
  position: relative;
  margin-bottom: 2rem;
  flex: 1 0 120px;
  width: 120px;
  height: 120px;
  border: 1px solid var(--orange);
  background: var(--orange);
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.article .subscription .subscriber-avatar {
  margin: 4px 0 0 20px;
}
.article .subscriber-avatar img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100%;
}
.article .subscriber-box p {
  line-height: 1.5em;
}
.article .subscription {
  padding: 20px 0 40px;
}
.article .subscription-expiration-warning {
  color: var(--red);
}
.article .subscriber-details {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 20px 0 0;
  border-top: 1px solid var(--lightgrey);
  border-bottom: 1px solid var(--lightgrey);
}
.article .subscriber-detail {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  padding: 0 20px 0 0;
}
.article .subscriber-detail-label {
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
}
.article .subscription-actions {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}
.article .subscription-actions li {
  margin: 0 0.8rem 0 0;
  padding: 0;
}
.article .subscription-actions li:last-of-type {
  margin-right: 0;
}
.article .subscription-actions li a {
  display: inline-block;
  padding: 0 1.8rem;
  height: 44px;
  border: 0;
  font-size: 1.5rem;
  line-height: 42px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.article .subscription-actions li a, .article .subscription-actions li a:hover {
  color: var(--orange);
  text-decoration: none;
  box-shadow: inset 0 0 0 1px var(--orange);
}
.article .subscription-actions li a:hover {
  transition: 0.2s ease;
}
.article .subscription-actions .gh-subscription-cancel, .article .subscription-actions .gh-subscription-cancel:hover {
  color: var(--midgrey);
  box-shadow: inset 0 0 0 1px var(--midgrey);
}
.article .gh-subscription-cancel[data-members-continue-subscription], .article .gh-subscription-cancel[data-members-continue-subscription]:hover {
  background-color: var(--orange);
  box-shadow: none;
}
.article .gh-error-subscription-cancel {
  display: block;
  margin-top: 10px;
  color: var(--red);
  font-size: 1.5rem;
}
.article .account-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 560px;
  width: 100%;
}
.article .account-box-title {
  margin-top: 20px;
  font-size: 3.4rem;
  text-align: center;
}
.article .account-box p {
  text-align: center;
}
.article .header-cta {
  margin-left: 20px;
}
.article .post-card-header-tags {
  display: flex;
  align-items: center;
  margin: 0 0 0.2em;
}
.article .post-visibility {
  display: inline-block;
  margin: 0 8px 1px 0;
  padding: 1px 5px;
  border: 1px solid var(--lightgrey);
  color: var(--midgrey);
  font-size: 1.2rem;
  line-height: 1.4em;
  border-radius: 4px;
}
.article .post-visibility-members, .article .post-visibility-paid {
  display: none;
}
.article .post-upgrade-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article .post-upgrade-cta-content {
  margin: 0 5vw;
  padding: 40px 30px;
  max-width: 840px;
  width: 90vw;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  text-align: center;
  background: var(--whitegrey);
  border-radius: 5px;
}
.article .post-upgrade-cta-content h1 {
  margin-bottom: 3rem;
  min-width: 0;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.article .post-upgrade-cta-content h2 {
  margin: 0;
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: -0.5px;
}
.article .post-upgrade-cta-content .form-group-max-width {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.article .post-upgrade-cta-content .form-group {
  margin-bottom: 2rem;
}
.article .post-upgrade-cta-content .subscribe-email {
  padding: 2rem;
}
.article .post-upgrade-cta .button, .article .post-upgrade-cta .button:hover {
  margin: 30px 0;
  text-decoration: none;
}
.article .post-upgrade-cta p {
  margin: 0;
}
.article .post-upgrade-cta a, .article .post-upgrade-cta a:hover {
  text-decoration: underline;
  box-shadow: none !important;
}
@media (max-width: 600px) {
  .article .subscriber-details {
    flex-direction: column;
  }
  .article .subscriber-avatar {
    display: none;
  }
}
.article .subscribe-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 5.4rem 0;
  text-align: left;
}
.article .subscribe-form-header {
  margin-right: 2rem;
}
.article .subscribe-form-title {
  margin-top: 0;
  padding: 0;
  line-height: 1.2;
  font-weight: 700;
}
.article .subscribe-form-description {
  font-weight: 500;
  letter-spacing: 0.2px;
}
.article .subscribe-form-description p {
  margin-bottom: 0;
}
.article .subscribe-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 520px;
}
.article .subscribe-form .form-group {
  display: block;
  position: relative;
  width: 100%;
}
.article .subscribe-email {
  display: block;
  width: 100%;
  padding: 2rem 17rem 2rem 2rem;
  border: 1px solid var(--lightgrey);
  color: var(--text-secondary);
  font-size: 1.4rem;
  line-height: 121%;
  font-weight: 500;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  border-radius: 10px;
  transition: border-color 0.15s linear;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  .article .subscribe-email {
    padding: 1.5rem 2rem;
    margin-bottom: 1.2rem;
    border-radius: 8px;
  }
}
.article .subscribe-email::-moz-placeholder {
  color: var(--text-light);
  font-weight: 400;
}
.article .subscribe-email:-ms-input-placeholder {
  color: var(--text-light);
  font-weight: 400;
}
.article .subscribe-email::placeholder {
  color: var(--text-light);
  font-weight: 400;
}
.article .subscribe-email:focus {
  outline: 0;
  border-color: #ff5e29;
}
.article .subscribe-form form.aweber-subscribe-form button, .article .subscribe-form form[data-members-form] button {
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  margin-right: 0;
}
@media (max-width: 767px) {
  .article .subscribe-overlay form.aweber-subscribe-form button, .article .subscribe-overlay form[data-members-form] button {
    position: static;
    width: 100%;
  }
}
.article .subscribe-form .button-content .button-content-icon {
  margin-left: 1.1rem;
}
.article .subscribe-form-small {
  background: 0 0;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.article .subscribe-form-small form {
  margin: 0;
}
@media (max-width: 991px) {
  .article .subscribe-form {
    flex-direction: column;
  }
  .article .subscribe-form-header {
    margin-bottom: 4.4rem;
    text-align: center;
  }
  .article .subscribe-form-small .button, .article .subscribe-section .button {
    position: absolute;
    width: auto;
  }
}
@media (max-width: 767px) {
  .article .subscribe-form-small form.aweber-subscribe-form .button, .article .subscribe-form-small form[data-members-form] .button, .article .subscribe-section form.aweber-subscribe-form .button, .article .subscribe-section form[data-members-form] .button {
    position: absolute;
    width: auto;
    top: 3px;
    right: 3px;
  }
}
@media (max-width: 650px) {
  .article .subscribe-form-title {
    font-size: 2.4rem;
  }
  .article .subscribe-form-description {
    font-size: 1.6rem;
  }
}
@media (max-width: 500px) {
  .article .subscribe-form form {
    flex-direction: column;
  }
  .article .subscribe-form .form-group {
    flex-direction: column;
    width: 100%;
  }
  .article .subscribe-form-small .subscribe-email, .article .subscribe-section .subscribe-email {
    padding: 15px 124px 15px 15px;
    font-size: 14px;
  }
  .article .subscribe-form-small form.aweber-subscribe-form .button, .article .subscribe-form-small form[data-members-form] .button, .article .subscribe-section form.aweber-subscribe-form .button, .article .subscribe-section form[data-members-form] .button {
    width: auto;
    top: 6px;
    right: 6px;
  }
}
@media (max-height: 500px) and (orientation: landscape) {
  .article .subscribe-form {
    padding-top: 15rem;
  }
  .article .subscribe-overlay .subscribe-form-title {
    font-size: 3.2rem;
  }
  .article .subscribe-overlay .subscribe-form-header {
    margin-bottom: 1.8rem;
  }
}
.article .site-footer {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: var(--text-primary);
  background: var(--footer-bg);
}
.article .site-footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.2rem;
}
.article .site-footer-top {
  padding-top: 1.2rem;
  padding-bottom: 4rem;
}
.article .site-footer-bottom {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.article .site-footer-logo {
  display: block;
  max-width: 256px;
  margin-bottom: 1.8rem;
}
.article .site-footer-description {
  max-width: 83.3%;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.2px;
}
.article .site-footer-about, .article .site-footer-menus {
  width: 50%;
}
.article .site-footer-menus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.article .site-footer-menu {
  width: 33.3%;
  padding-top: 1rem;
  margin-right: 40px;
}
.article .site-footer-menu h4 {
  text-transform: uppercase;
  margin: 0 0 1.2rem;
}
.article .site-footer-menu ul {
  padding: 0;
  margin: 0;
}
.article .site-footer-menu li {
  display: block;
  list-style-type: none;
  margin: 1.2rem 0;
  padding-left: 0;
}
.article .site-footer-menu li a {
  font-size: 1.6rem;
  line-height: 1.187;
}
.article .site-footer-menu:last-child {
  margin-right: 0;
}
.article .site-footer-menu-icon {
  display: inline-flex;
  align-self: center;
  width: auto;
  min-width: 18px;
  height: 0.9375em;
  margin-right: 8px;
  position: relative;
}
.article .site-footer-menu-icon svg {
  width: auto;
  height: 100%;
}
.article .site-footer-menu-icon.twitter-icon {
  top: 1px;
}
.article .site-footer-menu-icon.contact-icon {
  top: 2px;
}
.article .site-footer-menu-icon.discord-icon {
  top: 1px;
}
.article .site-footer-content a {
  color: var(--text-primary);
}
.article .site-footer-content a:hover {
  color: var(--text-secondary);
  text-decoration: none;
}
.article .site-footer-menu a:hover {
  color: var(--orange);
  text-decoration: none;
}
.article .site-footer-nav {
  display: flex;
}
.article .site-footer-nav a {
  position: relative;
  margin-right: 3.2rem;
  text-decoration: underline;
}
.article .site-footer-nav a:hover {
  text-decoration: none;
}
.article .copyright {
  color: var(--text-secondary);
}
@media (max-width: 991px) {
  .article .site-footer-about {
    width: 100%;
    margin-bottom: 5rem;
  }
  .article .site-footer-description {
    max-width: 100%;
  }
  .article .site-footer-menus {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .article .site-footer-content {
    flex-direction: column;
  }
  .article .site-footer-menus {
    align-self: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .article .site-footer-menus .site-footer-menu {
    margin-right: 8rem;
  }
  .article .site-footer-bottom {
    flex-direction: column;
  }
  .article .site-footer-bottom .site-footer-nav {
    order: 2;
    margin-top: 2.2rem;
  }
  .article .site-footer-bottom .site-footer-nav a {
    margin: 0 1.6rem;
  }
}
@media (max-width: 500px) {
  .article .site-footer-menus {
    margin-left: -3vw;
    margin-right: -3vw;
    justify-content: space-between;
  }
  .article .site-footer-menus .site-footer-menu {
    flex: 0 1 48%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 3vw;
    padding-right: 3vw;
  }
}
.article .page-template .post-full-header {
  padding-bottom: 15px;
}
@media (min-width: 900px) {
  .article .author-template .post-feed, .article .home-template .post-feed, .article .tag-template .post-feed {
    padding: 40px 0 5vw;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .article .home-template .site-nav {
    position: relative;
  }
}
.article .grid, .article .podcast-hosts {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 -1.65rem;
  align-self: stretch;
}
.article .post-full-content .grid {
  margin-top: 1.95rem;
  margin-bottom: 3.7rem;
}
.article .post-full-content .grid a.grid-item, .article .post-full-content .grid a.grid-item:hover {
  box-shadow: none;
}
.article .grid-item, .article .podcast-host {
  flex: 0 1 25%;
  padding: 0 1.65rem;
}
.article .podcast-host {
  margin: 2.2rem 0;
}
.article .grid-item {
  margin: 1.65rem 0;
}
.article .post-full-content a.grid-item {
  box-shadow: none;
}
.article .post-full-content a.grid-item .image-thumb {
  transition: box-shadow 0.3s ease;
  box-shadow: none;
}
.article .post-full-content a.grid-item:hover .image-thumb {
  box-shadow: 0 12px 64px rgba(14, 86, 124, 0.1);
}
.article .host-card {
  width: 100%;
}
.article .host-card .host-card-image {
  width: 100%;
  padding-top: 100%;
  border-radius: 30px;
  overflow: hidden;
  margin: 0 0 2.2rem;
  position: relative;
}
.article .host-card .host-card-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.article .host-card .host-card-name, .article .host-card h4.host-card-name {
  font-size: 1.4rem;
  line-height: 1.21;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 1.2rem;
}
.article .host-card .host-card-info {
  font-size: 1.4rem;
  line-height: 1.43;
}
.article .host-card .host-card-info a {
  font-size: inherit;
  color: var(--orange);
  text-decoration: none;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}
.article .host-card .host-card-info a:hover {
  color: #f34a12;
  box-shadow: inset 0 -1px 0 #f34a12;
}
.article .host-card .host-card-info :last-child {
  margin-bottom: 0;
}
.article .image-thumb {
  display: block;
  width: 100%;
  padding-top: 100%;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 0;
  position: relative;
}
.article .image-thumb img {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
@media (max-width: 991px) {
  .article .grid, .article .podcast-hosts {
    margin: 0 -1.45rem;
  }
  .article .grid-item, .article .podcast-host {
    padding: 0 1.45rem;
  }
  .article .grid-item {
    margin: 1.45rem 0;
  }
}
@media (max-width: 767px) {
  .article .grid, .article .poscast-hosts {
    margin: 0 -6px;
  }
  .article .podcast-host {
    flex: 0 1 50%;
    padding: 0 6px;
    margin: 16px 0;
  }
  .article .grid-item {
    padding: 0 6px;
    margin: 6px 0;
  }
}
@media (max-width: 500px) {
  .article .post-full-content .grid {
    margin-top: 18px;
    margin-bottom: 28px;
  }
  .article .host-card .host-card-info, .article .host-card .host-card-name, .article .host-card h4.host-card-name {
    font-size: 14px;
  }
  .article .image-thumb {
    border-radius: 18px;
  }
}
.article .card, .article a.card {
  display: flex;
  flex-flow: row;
  align-items: center;
  border: 1px solid var(--whitegrey-darker);
  border-radius: 30px;
  margin-bottom: 2.2rem;
  color: var(--text-primary);
  text-decoration: none;
  box-shadow: none;
  transition: box-shadow 0.3s ease;
}
.article .card:hover, .article a.card:hover {
  box-shadow: 0 12px 64px rgba(14, 86, 124, 0.1);
}
.article .card-media {
  flex: 0 1 32rem;
  min-height: 150px;
  padding: 1rem 0 1rem 1rem;
}
.article .card-media .card-image, .article .card-media img {
  display: block;
  margin: 0;
  width: 100%;
  height: 130px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 22px;
}
.article .card-media .card-video-image {
  height: 250px;
}
.article .card-content {
  flex: 1;
  padding: 3.2rem;
  align-self: stretch;
}
.article .card-title, .article h4.card-title {
  font-size: 1.4rem;
  line-height: 1.2;
  letter-spacing: 0.5px;
  margin: 0 0 1.2rem;
  text-transform: uppercase;
}
.article .card-text {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--text-secondary);
}
.article .card-text > :last-child {
  margin-bottom: 0;
}
.article .card-video-thumb {
  width: 100%;
  height: 250px;
  margin: 0 auto;
  position: relative;
  border-radius: 22px;
  overflow: hidden;
}
.article .card-video-thumb .card-video-image, .article .card-video-thumb img {
  height: 250px;
}
.article .card-video-thumb:after, .article .card-video-thumb:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.article .card-video-thumb:before {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
}
.article .card-video-thumb:after {
  z-index: 2;
  width: 0;
  height: 0;
  border-top: 8.5px solid transparent;
  border-bottom: 8.5px solid transparent;
  border-left: 15px solid #fff;
  margin-left: 2px;
}
@media (max-width: 767px) {
  .article .card, .article a.card {
    flex-direction: column;
    align-items: stretch;
  }
  .article .card-media {
    flex: 1;
    padding: 10px 10px 0;
  }
  .article .card-content {
    padding: 32px 20px;
  }
  .article .card-text, .article .card-title, .article h4.card-title {
    font-size: 14px;
  }
}
.article .post-full-top-margin {
  margin-top: 6rem;
}
.article .home-banner {
  min-height: 660px;
  margin-bottom: 6.7rem;
  padding-top: 10.4rem;
  padding-bottom: 20rem;
  /* background: url(../img/hero.png) bottom no-repeat; */
  background-size: cover;
}
/* @media (min-width: 1280px) {
  .article .home-banner {
    background-image: url(../img/hero@2x.png);
  }
} */
/* @media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px), (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 768px) {
  .article .home-banner {
    background-image: url(../img/hero@2x.png);
  }
} */
@media (max-width: 991px) {
  .article .home-banner {
    padding-bottom: 12rem;
  }
}
/* @media (max-width: 500px) {
  .article .home-banner {
    margin-bottom: 4rem;
    padding-top: 6rem;
    padding-bottom: 18rem;
    background-image: url(../img/hero-mobile.png);
  }
} */
.article .home-banner-content {
  max-width: 50%;
}
@media (max-width: 991px) {
  .article .home-banner-content {
    max-width: 430px;
  }
}
@media (max-width: 767px) {
  .article .home-banner-content {
    max-width: 100%;
  }
}
.article .home-banner-title {
  margin-bottom: 2.8rem;
}
.article .home-banner-text {
  max-width: 430px;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 162%;
  letter-spacing: 0.2px;
  margin-bottom: 4.4rem;
}
.article .home-banner-subscribe {
  max-width: 430px;
}
.article .top-stories {
  margin: 6.7rem 0 6rem;
}
.article .top-stories .h5, .article .top-stories h5 {
  margin-bottom: 1.6rem;
}
.article .top-stories-inner {
  margin-top: 6.8rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .article .top-stories-inner {
    flex-direction: column;
    margin-top: 2.8rem;
  }
}
.article .featured-stories-feed {
  width: 100%;
  max-width: 263px;
  margin-left: 2.6rem;
}
@media (max-width: 767px) {
  .article .featured-stories-feed {
    max-width: 100%;
    margin-top: 3.6rem;
    margin-left: 0;
  }
}
.article .advertise-section {
  margin-top: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
  background: #fdf3e6;
}
.article .advertise-main {
  text-align: center;
  max-width: 600px;
  margin: 0 auto 56px;
}
@media (max-width: 767px) {
  .article .advertise-main {
    padding: 0;
    margin-bottom: 80px;
  }
}
.article .advertise-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: stretch;
  font-weight: 500;
  position: relative;
}
.article .advertise-left, .article .advertise-right {
  width: 43.15%;
  padding: 0 3%;
  text-align: center;
}
.article .advertise-left h2, .article .advertise-right h2 {
  margin-top: 0;
  margin-bottom: 0.6em;
}
.article .advertise-left:after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  background: #000;
  opacity: 0.1;
}
.article .advertise-text {
  max-width: 390px;
  margin: 0 auto 3.2rem;
}
@media (min-width: 768px) and (max-width: 991px) {
  .article .advertise-left, .article .advertise-right {
    width: 48%;
    padding: 0 2%;
  }
  .article .advertise-text {
    max-width: 250px;
  }
}
@media (max-width: 767px) {
  .article .advertise-content {
    flex-flow: column nowrap;
  }
  .article .advertise-left, .article .advertise-right {
    width: 100%;
    padding: 0;
  }
  .article .advertise-left {
    margin-bottom: 120px;
    position: relative;
  }
  .article .advertise-left:after {
    width: 100%;
    height: 1px;
    bottom: -60px;
    left: 0;
    top: auto;
    right: 0;
  }
  .article .advertise-text {
    max-width: 100%;
  }
}
.article .round-icon {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto 20px;
  position: relative;
}
.article .round-icon svg {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.article .community-banner {
  padding: 3.2rem 2.8rem;
  border-radius: 10px;
  background: linear-gradient(90deg, #ffecd2, #fcb69f);
  color: var(-text-primary);
  text-align: center;
}
.article .community-banner .community-icon {
  display: block;
  width: 8rem;
  height: 8rem;
  margin: 0 auto 2rem;
  padding: 1.3rem 1.3rem 1.7rem 1.7rem;
  border-radius: 50%;
  background-color: #fff;
}
.article .community-banner .community-logo {
  display: block;
  width: 5rem;
  height: 5rem;
  /* background: url(../img/logo-small.svg) 50% no-repeat; */
  background-size: 5rem;
}
.article .community-banner .community-title {
  font-size: 2rem;
  line-height: 1.25;
}
.article .community-banner .community-text {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2.8rem;
}
.article .community-banner .community-buttons .button {
  width: 100%;
  max-width: 263px;
  min-height: 0;
  margin-top: 1.2rem;
  padding: 1.1rem 1.5rem;
}
.article .latest-podcast {
  padding-top: 2rem;
  padding-bottom: 0.1rem;
}
.article .latest-podcast h2 {
  margin-top: 0;
}
.article .tv-section {
  padding: 60px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.article .tv-section-image {
  flex: 1;
  position: relative;
}
.article .tv-section-image.with-bg {
  padding: 35px 0 0 40px;
}
.article .tv-section-image-holder {
  display: block;
  width: 100%;
  padding-top: 77.083%;
  position: relative;
}
.article .tv-section-image-holder img {
  min-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
.article .tv-section-image .rounded-shadow {
  border-radius: 30px;
  box-shadow: 0 32px 84px rgba(14, 86, 124, 0.35);
}
.article .tv-section-image-holder:before {
  content: "";
  display: none;
  width: 15.9rem;
  height: 19.2rem;
  overflow: hidden;
  position: absolute;
  top: -35px;
  left: -40px;
  z-index: 0;
  /* background-image: url(/assets/img/dots-bg.png); */
  background-size: 15.9rem 19.2rem;
}
.article .tv-section-image.with-bg .tv-section-image-holder:before {
  display: block;
}
.article .tv-section-content {
  flex: 0 1 51.5rem;
  margin-left: 9.1rem;
}
.article .tv-section-content .h5, .article .tv-section-content h5 {
  font-weight: 700;
  margin-bottom: 2.9rem;
}
.article .tv-section-text {
  max-width: 46rem;
  margin-bottom: 4.4rem;
  font-weight: 500;
}
.article .tv-section.text-first .tv-section-image.with-bg {
  padding: 35px 40px 0 0;
}
.article .tv-section.text-first .tv-section-image-holder:before {
  left: auto;
  right: -40px;
}
.article .tv-section.text-first .tv-section-content {
  margin: 0 9.1rem 0 0;
}
@media (max-width: 1160px) {
  .article .tv-section-content {
    flex: 0 0 46rem;
    margin-left: 5.9rem;
  }
  .article .tv-section.text-first .tv-section-content {
    margin: 0 5.9rem 0 0;
  }
}
@media (max-width: 991px) {
  .article .tv-section {
    align-items: flex-start;
  }
  .article .tv-section-content {
    flex: 0 0 36rem;
  }
  .article .tv-section-image.with-bg {
    padding: 39px 0 0 24px;
  }
  .article .tv-secton-image .rounded-shadow {
    box-shadow: 0 18.1146px 47.5508px rgba(14, 86, 124, 0.35);
    border-radius: 20px;
  }
  .article .tv-section-image-holder:before {
    width: 96px;
    height: 116px;
    background-size: 96px 116px;
    top: -39px;
    left: -24px;
  }
  .article .tv-section.text-first .tv-section-image.with-bg {
    padding: 39px 24px 0 0;
  }
  .article .tv-section.text-first .tv-section-image-holder:before {
    left: auto;
    right: -24px;
  }
}
@media (max-width: 767px) {
  .article .tv-section {
    padding: 30px 0;
    flex-flow: column nowrap;
  }
  .article .tv-section-content {
    flex: 1;
    margin-left: 0;
  }
  .article .tv-section-text {
    max-width: 100%;
    margin-bottom: 32px;
  }
  .article .tv-section-image {
    width: 100%;
    margin-bottom: 60px;
  }
  .article .tv-section-image.with-bg {
    padding: 29px 0 0 24px;
  }
  .article .tv-section-image-holder:before {
    left: -24px;
    top: -29px;
  }
  .article .tv-section .tv-section-image {
    order: 2;
  }
  .article .tv-section .tv-section-image.with-bg {
    padding: 29px 24px 0 0;
  }
  .article .tv-section .tv-section-image-holder:before {
    left: auto;
    right: -24px;
  }
  .article .tv-section .tv-section-content {
    order: 1;
    margin-bottom: 10px;
  }
}
.article .testimonials {
  padding: 10rem 0 8rem;
  border-top: 1px solid #eae9f2;
}
.article .testimonials-main {
  text-align: center;
  font-weight: 500;
  margin-bottom: 8rem;
}
.article .testimonials-main-text {
  max-width: 52.3rem;
  margin: 0 auto;
}
.article .testimonials-list {
  display: flex;
  margin: 0 -15px;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.article .testimonial {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 calc(33.3% - 30px);
  min-height: 37.4rem;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.036062);
  border-radius: 10px;
  margin: 0 15px 2rem;
  padding: 4rem 2rem 4rem 3.6rem;
  transition: box-shadow 0.3s ease;
}
.article .testimonial:hover {
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
}
.article .testimonial-rating {
  color: #d8d7e6;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 3rem;
  height: 1.6rem;
}
.article .testimonial-rating .icon-star {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 4px;
}
.article .testimonial-rating svg {
  display: block;
}
.article .testimonial-rating.star0 {
  color: #d8d7e6;
}
.article .testimonial-rating.star1 {
  color: var(--orange);
}
.article .testimonial-rating.star1 .icon-star:first-child ~ .icon-star {
  color: #d8d7e6;
}
.article .testimonial-rating.star2 {
  color: var(--orange);
}
.article .testimonial-rating.star2 .icon-star:nth-child(2) ~ .icon-star {
  color: #d8d7e6;
}
.article .testimonial-rating.star3 {
  color: var(--orange);
}
.article .testimonial-rating.star3 .icon-star:nth-child(3) ~ .icon-star {
  color: #d8d7e6;
}
.article .testimonial-rating.star4 {
  color: var(--orange);
}
.article .testimonial-rating.star4 .icon-star:nth-child(4) ~ .icon-star {
  color: #d8d7e6;
}
.article .testimonial-rating.star5 {
  color: var(--orange);
}
.article .testimonial-text {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.67;
  letter-spacing: 0.2px;
}
.article .testimonial-author {
  margin-top: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.article .testimonial-author-image {
  width: 4.3rem;
  height: 4.3rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 17px;
}
.article .testimonial-author-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.article .testimonial-author-data {
  flex: 1 1 auto;
}
.article .testimonial-author-name {
  font-size: 2.1rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.article .testimonial-author-extra {
  font-size: 1.6rem;
  line-height: 1.19;
  color: #696871;
}
@media (max-width: 991px) {
  .article .testimonials-list {
    flex-flow: column nowrap;
    margin: 0;
  }
  .article .testimonial {
    width: 100%;
    min-height: 32.3rem;
    margin: 0 0 2rem;
    padding-left: 3.6rem;
    padding-right: 3.6rem;
  }
}
@media (max-width: 500px) {
  .article .testimonials-main {
    margin-bottom: 44px;
  }
  .article .testimonial {
    width: 100%;
    min-height: 282px;
    margin: 0 0 12px;
    padding: 30px 20px;
  }
  .article .testimonial-text {
    font-size: 14px;
    line-height: 1.6;
  }
  .article .testimonial-author-image {
    width: 34px;
    height: 34px;
  }
  .article .testimonial-author-extra, .article .testimonial-author-name {
    font-size: 12px;
    line-height: 1.25;
  }
}
.article .page-search .post-content {
  margin-top: 4rem;
  text-align: center;
}
.article .page-search .post-content p {
  margin-bottom: 1rem;
}
.article .page-search .post-full-content .unibox__content-container {
  width: auto;
}
.article .page-search .post-full-content .unibox-selectable {
  align-items: center;
}
.article .page-search .post-full-content .unibox__selectable:hover {
  background: var(--whitegrey-darker);
}
.article .page-search .post-full-content .unibox__selectable:hover .unibox__content-container {
  background: 0 0;
}
.article .page-search .post-full-content .unibox__img {
  margin: 0;
}
.article .page-search .post-full-content .unibox__search-content--link:not(.button):not(.card) {
  color: var(--text-primary);
  box-shadow: none;
}
.article .page-search .post-full-content .ss360-list {
  padding: 0;
}
.article .page-search .post-full-content .ss360-suggests {
  padding-left: 0;
  border-radius: 10px;
  box-shadow: 0 2px 7px rgba(105, 105, 105, 0.08);
  transition: box-shadow 0.3s ease;
}
.article .page-search .post-full-content .ss360-suggests:hover {
  box-shadow: 0 4px 7px rgba(105, 105, 105, 0.28);
}
.article .page-search .post-full-content #ss360-layer .ss360-ac-c {
  color: var(--text-primary);
}
.article .page-search .post-full-content #ss360-layer .ss360-ac-c--hover:focus, .article .page-search .post-full-content #ss360-layer .ss360-ac-c--hover:hover, .article .page-search .post-full-content #ss360-layer .ss360-ac-c:hover {
  color: var(--orange);
}
.article .page-search .post-full-content .ss360-ac-bg, .article .page-search .post-full-content .ss360-ac-bg--focus:focus, .article .page-search .post-full-content .ss360-ac-bg--hover:focus, .article .page-search .post-full-content .ss360-ac-bg--hover:hover {
  background: var(--orange);
  border-color: var(--orange);
}
.article .page-search .post-full-content .ss360-acd-b--hover:focus, .article .page-search .post-full-content .ss360-acd-b--hover:hover {
  color: #fff;
  background: var(--orange-light);
}
.article .page-search .post-full-content .ss360-suggests__image-wrap {
  border-radius: 10px 0 0 10px;
  box-shadow: none !important;
}
.article .page-search .post-full-content .ss360-suggests__image {
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.article .page-search .post-full-content #ss360-layer .ss360-ac-b, .article .page-search .post-full-content #ss360-layer .ss360-ac-b--focus:focus, .article .page-search .post-full-content #ss360-layer .ss360-ac-b--hover:focus, .article .page-search .post-full-content #ss360-layer .ss360-ac-b--hover:hover {
  border-color: var(--orange);
  color: var(--orange);
}
.article .page-search .post-full-content #ss360-layer .ss360-ac-bg, .article .page-search .post-full-content #ss360-layer .ss360-ac-bg--focus:focus, .article .page-search .post-full-content #ss360-layer .ss360-ac-bg--hover:focus, .article .page-search .post-full-content #ss360-layer .ss360-ac-bg--hover:hover {
  color: #fff;
  background-color: var(--orange);
}
.article .img {
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  border-radius: 100%;
}
.article .hidden {
  visibility: hidden;
  position: absolute;
  text-indent: -9999px;
}
.article .uppercase {
  text-transform: uppercase;
}
.article .text-bold {
  font-weight: 700;
}
.article .text-blue {
  color: var(--blue);
}
.article .text-orange {
  color: var(--orange);
}
.article .text-purple {
  color: var(--purple);
}
.article .text-center {
  text-align: center;
}
.article .my-0 {
  margin-bottom: 0;
}
.article .mt-0, .article .my-0 {
  margin-top: 0;
}
.article .mb-0 {
  margin-bottom: 0;
}
.article .mt-1 {
  margin-top: 1rem;
}
.article .mb-1 {
  margin-bottom: 1rem;
}
.article .mt-2 {
  margin-top: 2rem;
}
.article .mb-2 {
  margin-bottom: 2rem;
}

@media (max-width: 500px) {
  .article .post-full-content .kg-card.kg-embed-card iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
