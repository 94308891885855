@media only screen and (max-width: 767px) {
  .table-responsive table {
    width: 100%;
  }
  .table-responsive table,
  .table-responsive thead,
  .table-responsive tbody,
  .table-responsive th,
  .table-responsive td,
  .table-responsive tr {
    display: block;
  }
  .table-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table-responsive tr {
    border-bottom: 1px solid #e2e8f0;
  }
  .table-responsive td {
    border: none;
    position: relative;
    padding-left: 35% !important;
  }
  .table-responsive td .row-checkbox {
    position: absolute;
    left: 10px;
  }
  .table-responsive td:before {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30%;
    padding-right: 10px;
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: 600;
    content: attr(data-column);
    height: 40px;
    padding-top: 12px;
  }
}


:root {
  --blue: #6d75e7;
  --blue-light: #008de4;
  --green: #a4d037;
  --green-dark: #12b077;
  --purple: #b54ac7;
  --yellow: #fecd35;
  --red: #f05230;
  --darkgrey: #15171a;
  --midgrey: #738a94;
  --lightgrey: #e5e5e5;
  --whitegrey: #fafafa;
  --whitegrey-darker: #f6f6f6;
  --pink: #fa3a57;
  --brown: #a3821a;
  --darkmode: #1a1c20;
  --orange: #f63;
  --orange-light: #ff8c66;
  --body-bg: #fff;
  --header-bg: #fff;
  --footer-bg: var(--whitegrey);
  --card-bg: #fff;
  --text-primary: #000;
  --text-secondary: #4b5157;
  --text-light: #939699;
  --button-bg-primary: var(--orange);
  --button-bg-primary-lighter: var(--orange-light);
  --button-bg-secondary: var(--blue);
  --button-color: #fff;
  --container-3xl: 1600px;
  --container-2xl: 1340px;
  --container-xl: 1280px;
  --container-width: 1126px;
  --viewport-xl: 1280px;
  --viewport-lg: 1160px;
  --viewport-md2: 1024px;
  --viewport-md: 991px;
  --viewport-sm: 768px;
  --viewport-xs: 500px;
  --volte-medium: "voltemedium",Arial,sans-serif;
  --volte-regular: "volteregular",Arial,sans-serif;
  --roobert-medium: "roobertmedium",Arial,sans-serif;
  --roobert-light: "roobertlight",Arial,sans-serif;
  --toast-z-index: 15000;
}