.notification-close {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}
.notification-close:before {
  transform: rotate(45deg);
}
.notification-close:after, .notification-close:before {
  content: "";
  position: absolute;
  top: 29px;
  right: 25px;
  display: block;
  width: 18px;
  height: 2px;
  background: #fff;
  border-radius: 2px;
}
.notification-close:after {
  transform: rotate(-45deg);
}
@media (max-width: 767px) {
  .billing-cancel .notification-billing-update-cancel, .billing-success .notification-billing-update, .checkout-success .notification-checkout, .signin-failure .notification-signin-failure, .signin-success .notification-signin, .signup-success .notification-signup, .subscribe-success .notification-subscribe {
    transform: translateY(56px);
  }
}
.subscribe-success-message.close {
  visibility: hidden;
  transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(-175%);
}
.subscribe-close-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}
.subscribe-close-button {
  position: absolute;
  top: 16px;
  right: 20px;
  z-index: 2000;
  display: block;
  width: 40px;
  height: 40px;
}
.subscribe-close-button:before {
  transform: rotate(45deg);
}
.subscribe-close-button:after, .subscribe-close-button:before {
  content: "";
  position: absolute;
  top: 20px;
  right: 4px;
  display: block;
  width: 32px;
  height: 1px;
  background: #fff;
  opacity: 0.8;
}
.subscribe-close-button:after {
  transform: rotate(-45deg);
}
.subscribe-close-button:hover, .subscribe-close-overlay:hover {
  cursor: default;
}
.subscribe-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(9, 10, 11, 0.97);
  opacity: 0;
  transition: opacity 0.2s ease-in;
  pointer-events: none;
}
.subscribe-overlay:target {
  z-index: 10001;
  opacity: 1;
  pointer-events: auto;
}
.subscribe-overlay-content {
  position: relative;
  margin: 0 0 5vw;
  padding: 4vw;
  color: #fff;
  text-align: center;
}
.subscribe-overlay .subscribe-form {
  flex-direction: column;
  max-width: 620px;
  border: none;
  color: #fff;
  background: 0 0;
  font-family: var(--volte-medium);
}
.subscribe-overlay .attachment-form {
  max-width: 767px;
}
.subscribe-overlay .attachment-form .form {
  max-width: 700px;
}
.subscribe-overlay-logo {
  position: fixed;
  top: 18px;
  left: 20px;
  height: 45px;
}
.subscribe-overlay .subscribe-form-header {
  margin-bottom: 4.4rem;
  margin-right: 0;
  text-align: center;
}
.subscribe-overlay .subscribe-form-title {
  display: inline-block;
  margin: 0 0 10px;
  color: var(--whitegrey);
  font-size: 5.2rem;
  line-height: 1.15em;
}
.subscribe-overlay .subscribe-form-subtitle, .subscribe-overlay .subscribe-form-title {
  font-family: var(--volte-regular);
}
.subscribe-overlay .subscribe-form-description {
  margin: 0 auto;
  color: var(--lightgrey);
  font-size: 2.4rem;
  line-height: 1.3em;
  font-weight: 300;
  opacity: 0.8;
}
.subscribe-overlay .form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 540px;
}
.subscribe-overlay .form-group {
  flex-grow: 1;
}
.subscribe-overlay .subscribe-email {
  display: block;
  width: 100%;
  border: none;
  color: var(--midgrey);
  font-size: 2rem;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: 0.5px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  border-radius: 8px;
  transition: border-color 0.15s linear;
  -webkit-appearance: none;
}
.subscribe-email:focus {
  border-color: #e0e0e0;
}
.subscribe-overlay button {
  display: inline-block;
  padding: 0 25px;
  height: 52px;
  outline: none;
  color: #fff;
  font-size: 1.7rem;
  line-height: 38px;
  font-weight: 400;
  text-align: center;
  border-radius: 8px;
  -webkit-font-smoothing: subpixel-antialiased;
}
.subscribe-overlay .loading .button-loader {
  top: 5px;
}
.subscribe-section-colored {
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 3rem;
  background: linear-gradient(90deg, #ffecd2, #fcb69f);
}
.subscribe-section-colored .subscribe-form {
  margin-left: auto;
  margin-right: auto;
}
.subscribe-section {
  background: var(--footer-bg);
}
.subscribe-section .subscribe-form {
  padding: 4.4rem 0 3.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.subscribe-section .subscribe-form .form {
  max-width: 47.958%;
}
.subscribe-section .subscribe-form-title {
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 0;
}
.subscribe-section .subscribe-form-description {
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 8px 0 0;
}
@media (min-width: 992px) and (max-width: 1160px) {
  .subscribe-section-colored .subscribe-form .form {
    max-width: 430px;
  }
}
@media (max-width: 991px) {
  .subscribe-section-colored .subscribe-form {
    max-width: 480px;
  }
  .subscribe-section .subscribe-form {
    align-items: flex-start;
    border-bottom: 1px solid var(--lightgrey);
  }
  .subscribe-section .subscribe-form .form {
    max-width: 540px;
  }
  .subscribe-section .subscribe-form-header {
    text-align: left;
    margin-bottom: 3.2rem;
  }
}
@media (max-width: 500px) {
  .subscribe-overlay .subscribe-form-title {
    font-size: 3.8rem;
  }
  .subscribe-overlay .subscribe-form-description {
    font-size: 2.4rem;
  }
  .subscribe-overlay .subscribe-form .form.aweber-subscribe-form .button, .subscribe-overlay .subscribe-form .form[data-members-form] .button {
    position: static;
    margin: 0;
  }
}

.subscribe-form .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 520px;
}

.subscribe-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 5.4rem 0;
  text-align: left;
}
.subscribe-form-header {
  margin-right: 2rem;
}
.subscribe-form-title {
  margin-top: 0;
  padding: 0;
  line-height: 1.2;
  font-weight: 700;
}
.subscribe-form-description {
  font-weight: 500;
  letter-spacing: 0.2px;
}
.subscribe-form-description p {
  margin-bottom: 0;
}
.subscribe-form .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 520px;
}
.subscribe-form .form-group {
  display: block;
  position: relative;
  width: 100%;
}
.subscribe-email {
  display: block;
  width: 100%;
  padding: 2rem 17rem 2rem 2rem;
  border: 1px solid var(--lightgrey);
  color: var(--text-secondary);
  font-size: 1.4rem;
  line-height: 121%;
  font-weight: 500;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  border-radius: 10px;
  transition: border-color 0.15s linear;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  .subscribe-email {
    padding: 1.5rem 2rem;
    margin-bottom: 1.2rem;
    border-radius: 8px;
  }
}
.subscribe-email::-moz-placeholder {
  color: var(--text-light);
  font-weight: 400;
}
.subscribe-email:-ms-input-placeholder {
  color: var(--text-light);
  font-weight: 400;
}
.subscribe-email::placeholder {
  color: var(--text-light);
  font-weight: 400;
}
.subscribe-email:focus {
  outline: 0;
  border-color: #ff5e29;
}
.subscribe-form .form.aweber-subscribe-form button, .subscribe-form .form[data-members-form] button {
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  margin-right: 0;
}
@media (max-width: 767px) {
  .subscribe-overlay .form.aweber-subscribe-form button, .subscribe-overlay .form[data-members-form] button {
    position: static;
    width: 100%;
  }
}
.subscribe-form .button-content .button-content-icon {
  margin-left: 1.1rem;
}
.subscribe-form-small {
  background: 0 0;
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.subscribe-form-small .form {
  margin: 0;
}
@media (max-width: 991px) {
  .subscribe-form {
    flex-direction: column;
  }
  .subscribe-form-header {
    margin-bottom: 4.4rem;
    text-align: center;
  }
  .subscribe-form-small .button, .subscribe-section .button {
    position: absolute;
    width: auto;
  }
}
@media (max-width: 767px) {
  .subscribe-form-small .form.aweber-subscribe-form .button, .subscribe-form-small .form[data-members-form] .button, .subscribe-section .form.aweber-subscribe-form .button, .subscribe-section .form[data-members-form] .button {
    position: absolute;
    width: auto;
    top: 3px;
    right: 3px;
  }
}
@media (max-width: 650px) {
  .subscribe-form-title {
    font-size: 2.4rem;
  }
  .subscribe-form-description {
    font-size: 1.6rem;
  }
}
@media (max-width: 500px) {
  .subscribe-form .form {
    flex-direction: column;
  }
  .subscribe-form .form-group {
    flex-direction: column;
    width: 100%;
  }
  .subscribe-form-small .subscribe-email, .subscribe-section .subscribe-email {
    padding: 15px 124px 15px 15px;
    font-size: 14px;
  }
  .subscribe-form-small .form.aweber-subscribe-form .button, .subscribe-form-small .form[data-members-form] .button, .subscribe-section .form.aweber-subscribe-form .button, .subscribe-section .form[data-members-form] .button {
    width: auto;
    top: 6px;
    right: 6px;
  }
}
@media (max-height: 500px) and (orientation: landscape) {
  .subscribe-form {
    padding-top: 15rem;
  }
  .subscribe-overlay .subscribe-form-title {
    font-size: 3.2rem;
  }
  .subscribe-overlay .subscribe-form-header {
    margin-bottom: 1.8rem;
  }
}


.message-success {
  position: fixed;
  top: 84px;
  right: 20px;
  left: 20px;
  z-index: 9999;
  margin: 0 auto;
  padding: 10px 0;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.5em;
  font-weight: 500;
  text-align: center;
  background: var(--green);
  border-radius: 5px;
}